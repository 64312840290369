import React, { useState, useEffect, useRef } from 'react';
import Fileupload from '../../components/Fileupload';
import { OutlinedButton, IconButton, FilledButton } from '@jsluna/button';
import { Download, Edit, Plus, Cancel, List } from '@jsluna/icons';
import { Heading2 } from '@jsluna/typography';
import Page from '../../components/Page';
import style from './style.module.css';
import { useHistory } from 'react-router-dom';
import ProgressIndicator from '../../components/ProgressIndicator'
import { useMsal } from "@azure/msal-react";
import { acquireToken } from "../../authConfig";
import { trackError } from "../../appinsights/applogs";
import { Table } from '@jsluna/table';
import ReactExport from "react-export-excel";


const Budget = () => {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const [budgetData, setBudgetData] = useState([]);
    const [erroMessage, setErrorText] = useState('');
    const [selectedFile, setSelectedFile] = useState();
    //const [isMissingFile, setFileUpload] = useState(false);
    const [budgetsavemsg, setbudgetsavemsg] = useState(false);
    const [fileLoading, setFileLoading] = useState(false);
    const [showRemarks, setShowRemarks] = useState(false);
    const budgetExportRef = useRef(null);

    let displayColumns = [
        {
            name: 'EventId',
            accessor: rowData => ({ value: rowData.LeaseEvents_ID }),
            hideLabel: true,
            className: 'customCol',
        },
        {
            name: 'BudgetRent',
            accessor: rowData => ({
                value: rowData.BudgetRent ?
                    (!isNaN(rowData.BudgetRent) ?
                        Number(rowData.BudgetRent).toFixed(2) : rowData.BudgetRent)
                    : rowData.BudgetRent
            }),
            hideLabel: true,
            className: 'customCol',
        },
        {
            name: 'Agency',
            accessor: rowData => ({ value: rowData.Agency }),
            hideLabel: true,
            className: 'customCol',
        },
        {
            name: 'Comment',
            accessor: rowData => ({ value: rowData.Comment }),
            hideLabel: true,
            className: 'customCol',
        },
        {
            name: 'PropertyName',
            accessor: rowData => ({ value: rowData.PropertyName }),
            hideLabel: true,
            className: 'customCol',
        },
        {
            name: 'PropertySubType',
            accessor: rowData => ({ value: rowData.PropertySubType }),
            hideLabel: false,
            className: 'customCol',
        },
        {
            name: 'PropertyType',
            accessor: rowData => ({ value: rowData.PropertyType }),
            hideLabel: false,
            className: 'customCol',
        },
        {
            name: 'ReviewDate',
            accessor: rowData => ({ value: rowData.ReviewDate ? new Date(rowData.ReviewDate).toLocaleDateString() : '' }),
            hideLabel: true,
            className: 'customCol',
        },
        {
            name: 'StoreNumber',
            accessor: rowData => ({ value: rowData.StoreNumber }),
            hideLabel: false,
            className: 'customCol',
        },
        {
            name: 'SSLManager',
            accessor: rowData => ({ value: rowData.SSLManager }),
            hideLabel: false,
            className: 'customCol',
        },
        {
            name: 'LeaseRef',
            accessor: rowData => ({ value: rowData.LeaseRef }),
            hideLabel: false,
            className: 'customCol',
        },
        {
            name: 'LeaseStartDate',
            accessor: rowData => ({ value: rowData.LeaseStartDate ? new Date(rowData.LeaseStartDate).toLocaleDateString() : '' }),
            hideLabel: false,
            className: 'customCol',
        },
        {
            name: 'LeaseEndDate',
            accessor: rowData => ({ value: rowData.LeaseEndDate ? new Date(rowData.LeaseEndDate).toLocaleDateString() : '' }),
            hideLabel: false,
            className: 'customCol',
        },
        {
            name: 'EventType',
            accessor: rowData => ({ value: rowData.EventType }),
            hideLabel: true,
            className: 'customCol',
        },
        {
            name: 'EventStatus',
            accessor: rowData => ({ value: rowData.EventStatus }),
            hideLabel: false,
            className: 'customCol',
        },
        {
            name: 'PassingRent',
            accessor: rowData => ({ value: rowData.PassingRent }),
            hideLabel: false,
            className: 'customCol',
        },
        {
            name: 'BudgetStatus',
            accessor: rowData => ({ value: rowData.BudgetStatus }),
            hideLabel: false,
            className: 'customCol',
        },
        {
            name: 'Remarks',
            accessor: rowData => ({ value: rowData.Remarks }),
            hideLabel: true,
            className: 'customCol',
        }
    ];

    displayColumns = showRemarks ? displayColumns.filter((c) => c.hideLabel == true) : displayColumns.filter((c) => c.name !== 'Remarks');

    const handleSubmission = () => {
        if (selectedFile == null) {
            setErrorText("Please select the Budget Details Template for upload.");
        }

        else {
            const formData = new FormData();
            if (selectedFile) {
                formData.append('formFile', selectedFile);
                setFileLoading(true);
                acquireToken().then((response) => {
                    
                    var res = fetch(window.location.origin + '/api/file/BudgetUpload', {
                        method: 'POST',
                        headers: { Authorization: 'Bearer ' + response.accessToken },
                        body: formData,
                    });

                    res.then(response => response.json())
                        .then(data => {
                            if (typeof data === 'string') {
                                setErrorText(data);
                                setBudgetData([]);
                            }
                            else {
                                setErrorText('');
                                setShowRemarks(false);
                                setBudgetData(data);
                            }
                            setFileLoading(false);
                        })
                        .catch((error) => {
                            setFileLoading(false);
                            console.error('Error:', error);
                            trackError(error);
                        });
                });
            }
        }
    };

    const saveBudget = (e) => {

        if (selectedFile == null) {
            setErrorText("Please select the Budget Details Template for upload.");
        }

        else {
            //if (budgetData.length > 0) {
            //    setFileLoading(true);
            //    setbudgetsavemsg(true);
            //    setTimeout(function () {
            //        setbudgetsavemsg(false);
            //        setBudgetData([]);
            //    }, 3000);
            //    setFileLoading(false);
            //}

            const formData = new FormData();
            if (selectedFile && budgetData.length > 0) {
                formData.append('formFile', selectedFile);
                setFileLoading(true);
                acquireToken().then((response) => {

                    var res = fetch(window.location.origin + '/api/file/SaveBudgetDetails', {
                        method: 'POST',
                        headers: { Authorization: 'Bearer ' + response.accessToken },
                        body: formData,
                    });

                    res.then(response => response.json())
                        .then(data => {
                            if (typeof data === 'string') {
                                setErrorText(data);
                                setBudgetData([]);
                            }
                            else {
                                setErrorText('');
                                setShowRemarks(true);
                                setBudgetData(data);
                                setFileLoading(false);
                                setbudgetsavemsg(true);
                                setTimeout(function () {
                                    setbudgetsavemsg(false);
                                }, 3000);
                            }
                        })
                        .catch((error) => {
                            setFileLoading(false);
                            console.error('Error:', error);
                            trackError(error);
                        });
                });
            }
        }
    }

    const changeHandler = (event) => {
        setSelectedFile(null);
        const file = event.target.files[0];

        if (file !== null && file !== undefined) {
            const ext = file.name.split('.').pop();

            if (ext == "xlsx") {
                setSelectedFile(file);
                //setIsFileSelected(true);
                setErrorText("");
            } else {
                event.preventDefault();
                event.target.value = "";
                setErrorText("Invalid file type selected for Budget upload, please select xlsx file");
            }
        }
    };

    return (
        <div style={{ padding: '10px' }}>
            <ExcelFile filename={`lease-events-budget-${new Date().toLocaleString()}`} element={<div ref={budgetExportRef}></div>}>
                <ExcelSheet data={budgetData} name="Lease Event Budget">
                    <ExcelColumn label="LeaseEvents_ID" value="LeaseEvents_ID" />
                    <ExcelColumn label="Store Number" value="StoreNumber" />
                    <ExcelColumn label="Property Name" value="PropertyName" />
                    <ExcelColumn label="Property Type" value="PropertyType" />
                    <ExcelColumn label="Property Sub Type" value="PropertySubType" />
                    <ExcelColumn label="Lease Ref" value="LeaseRef" />
                    <ExcelColumn label="Lease Desc / Tenant" value="LeaseDesc/Tenant" />
                    <ExcelColumn label="Event Type" value="EventType" />
                    <ExcelColumn label="Review Date" value={(values) => {
                        var rdate = new Date(values.ReviewDate);
                        var offset = Math.abs(rdate.getTimezoneOffset());
                        return values.ReviewDate ? new Date(rdate.getFullYear(), rdate.getMonth(), rdate.getDate(), Math.floor(offset / 60), (offset % 60), 0) : null;
                    }} />
                    <ExcelColumn label="Passing Rent" value="PassingRent" />
                    <ExcelColumn label="SSL Manager" value="SSLManager" />
                    <ExcelColumn label="Property Event Type" value="PropertyEventType" />
                    <ExcelColumn label="Event Status" value="EventStatus" />
                    <ExcelColumn label="Budget Status" value="BudgetStatus" />
                    <ExcelColumn label="Landlord Name" value="LandlordName" />
                    <ExcelColumn label="Lease Start Date" value={(values) => {
                        //values.LeaseStartDate ? moment(new Date(values.reviewDate)).format("MM/DD/YYYY") : null
                        var sdate = new Date(values.LeaseStartDate);
                        var offset = Math.abs(sdate.getTimezoneOffset());
                        return values.LeaseStartDate ? new Date(sdate.getFullYear(), sdate.getMonth(), sdate.getDate(), Math.floor(offset / 60), (offset % 60), 0) : null;
                    }} />
                    <ExcelColumn label="Lease End Date" value={(values) => {
                       // values.LeaseEndDate ? moment(new Date(values.reviewDate)).format("MM/DD/YYYY") : null
                        var edate = new Date(values.LeaseEndDate);
                        var offset = Math.abs(edate.getTimezoneOffset());
                        return values.LeaseEndDate ? new Date(edate.getFullYear(), edate.getMonth(), edate.getDate(), Math.floor(offset / 60), (offset % 60), 0) : null;
                    }} />
                    <ExcelColumn label="Agency" value="Agency" />
                    <ExcelColumn label="Budget Rent" value={(values) => values.BudgetRent ? values.BudgetRent : null} />
                    <ExcelColumn label="Comment" value="Comment" />
                    <ExcelColumn label="Remarks" value="Remarks" />
                </ExcelSheet>
                {/*<ExcelSheet data={excelBudgetdata.agency} name="List of Agency">*/}
                {/*    <ExcelColumn label="Agency Id" value="id" />*/}
                {/*    <ExcelColumn label="AgencyName" value="name" />*/}
                {/*</ExcelSheet>*/}
            </ExcelFile>
            <h3 style={{ marginBottom: '0px' }}>Budget Import</h3>
            <div style={{ paddingLeft: '10px' }}>
                {erroMessage ? <div className={style.rralert}>{erroMessage}</div> : ''}
                {budgetsavemsg ? <div className={style.rrsuccessalert}> The Budget details updated successfully, View remarks for individual event success/failed. </div> : ""}
                <Fileupload onClick={() => handleSubmission()} onChange={changeHandler} acceptExtension=".xlsx" name="Budget Upload" />
                <FilledButton style={{ marginRight: '5px' }} onClick={() => saveBudget()} >Save Budget</FilledButton>
                {showRemarks ? <IconButton
                    name='budgetExportBtn'
                    variant='outlined'
                    label='Result Export'
                    style={{ float: 'right', marginTop: '5px' }}
                    onClick={(e) => { budgetExportRef.current.click(); }}
                >
                    <Download />
                </IconButton> : <></>
                }
                <br /><br />
                {budgetData.length > 0 ?
                    <div style={{ maxHeight: '375px', overflowY: 'scroll' }}>
                        <Table
                            rowKey="LeaseEvents_ID"
                            sortable
                            data={budgetData}
                            columns={displayColumns}
                        /> </div>
                    : <></>}
            </div>
            {fileLoading ?
                <ProgressIndicator />
                : <></>
            }
        </div>
    );
};

export default Budget;
