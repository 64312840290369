export default (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'UPDATE_FILTERS': {
      return {
        ...state,
        filterParams: { ...state.filterParams, ...action.payload },
      };
    }
    case 'SET_FILTERS':
      return {
        ...state,
        filterParams: action.payload,
      };
    case 'GET_EVENTS':
      return {
        ...state,
        loading: false,
        events: action.payload,
      };
    case 'EVENTS_ERROR':
      return {
        ...state,
        error: action.payload,
      };
    case 'GENERATE_FRF':
      return {
        ...state,
        loading: false,
        events: action.payload,
      };
    case 'SET_MAINTENANCE_MESSAGE':
      return {
        ...state,
        maintenanceMessage: action.payload,
      };
    case 'UPDATE_LISTING_OPTIONS':
      return {
        ...state,
        listingOptions: { ...state.listingOptions, ...action.payload },
          };
      case 'SET_MESSAGE':
          return {
              ...state,
              message: action.payload,
          };
    default:
      return {
        state,
      };
  }
};
