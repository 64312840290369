import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import 'bootstrap/dist/css/bootstrap.css';
import './main.scss'
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
//import unregisterServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
    
    <MsalProvider instance={msalInstance}>
        <BrowserRouter basename={baseUrl}>
            <App />
        </BrowserRouter>,
        </MsalProvider>,
  rootElement);

unregister();
