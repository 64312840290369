import React, { useState } from 'react';

import { Modal, ModalHeading } from '@jsluna/modal';
import {
  FilledButton,
  TextButton,
  ButtonGroupWrapper,
  ButtonGroupPrimary,
  ButtonGroupSecondary,
  IconButton,
} from '@jsluna/button';
import { Cancel } from '@jsluna/icons';
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress';

import { useMsal } from '@azure/msal-react';
import moment from 'moment';

import { acquireToken } from '../../authConfig';
import { AddEntries } from '../../pages/Details/api';
import { trackError } from '../../appinsights/applogs';

const NoteModal = ({ isOpen, setIsOpen, selectedProperties, saveComplete }) => {
  const { accounts } = useMsal();
  const [addNote, setaddNote] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [hasError, setHasError] = useState(false);

  const saveClick = async (event) => {
    event.preventDefault();
    if (addNote.trim()) {
      setIsSaving(true);
      const dtformat = 'YYYY-MM-DD';
      var dt = new Date();
      const note = {
        addedBy: accounts[0].name,
        addedDate: moment(dt).format(dtformat),
        note: addNote,
      };
      acquireToken().then((response) => {
        var res = AddEntries(selectedProperties, note, response.accessToken);
        res
          .then((response) => {
            setIsSaving(false);
            if (response.status === 200) {
              saveComplete();
            } else {
              setHasError(true);
            }
          })
          .catch((err) => {
            trackError(err);
            console.log(err);
            setHasError(true);
          });
      });
    }
  };

  const handleCloseClick = () => {
    setaddNote(null);
    setIsOpen(false);
  };

  const closeError = (e) => {
    e.preventDefault();
    setHasError(false);
  };

  return (
    <Modal
      fullScreen
      restrictClose
      alert
      handleClose={handleCloseClick}
      open={isOpen}
      headingId='dialog-modal'
    >
      <ModalHeading element='h3'>Add Note</ModalHeading>
      {hasError ? (
        <div className='ln-u-bg-color-error ln-u-color-white ln-u-margin-bottom ln-u-padding*2 ln-u-display-flex ln-u-justify-content-space-between ln-u-align-items-center'>
          <span>
            An error occurred when trying to save the note. Please try again. If
            symptom persists please raise an incident.
          </span>
          <IconButton
            variant='text'
            label='Close'
            hideLabel
            onClick={closeError}
            className='ln-u-color-white'
          >
            <Cancel />
          </IconButton>
        </div>
      ) : (
        ''
      )}
      <textarea
        rows='6'
        onChange={(e) => {
          setaddNote(e.target.value);
        }}
        style={{ width: '100%', border: '1px solid #adadad' }}
      ></textarea>
      <ButtonGroupWrapper>
        <ButtonGroupSecondary>
          <TextButton onClick={() => setIsOpen(false)}>Close</TextButton>
        </ButtonGroupSecondary>
        <ButtonGroupPrimary>
          {!isSaving ? (
            <FilledButton onClick={(e) => saveClick(e)}>Save</FilledButton>
          ) : (
            <FilledButton disabled>
              <ProgressIndicator
                className='ln-u-justify-content-center'
                loading
                preventFocus
              >
                <ProgressSpinner size='icon' className='ln-u-push-right-sm' />
                Saving
              </ProgressIndicator>
            </FilledButton>
          )}
        </ButtonGroupPrimary>
      </ButtonGroupWrapper>
    </Modal>
  );
};

export default NoteModal;
