import React, { useState, useContext} from 'react'
import { Sainsburys } from '@jsluna/images'
import {
    Header,
    HeaderLogo,
} from '@jsluna/react'
import { HeaderSearch } from '@jsluna/header'
import { Link, useHistory, useLocation } from 'react-router-dom'
import './Header.scss'
import { AutocompleteField } from '@jsluna/autocomplete'
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { msalConfig } from "../../authConfig";
import { trackError } from "../../appinsights/applogs";
import { GlobalContext } from '../../context/GlobalState';

export default function Headers() {
    const { updateFilters, maintenanceMessage } = useContext(GlobalContext);
    const options = [
        { label: "Property Name", value: 'Name' },
        { label: "SSL Manager", value: 'SSLManager' }        
    ];

    const [propertyselectvalue, setPropertyselectvalue] = useState();   
    const history = useHistory();
    const location = useLocation();

    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();

    const validateUserRole = () => {

        for (let account of accounts) {
            if (account.idTokenClaims && account.idTokenClaims.aud === msalConfig.auth.clientId) {
                if (account.idTokenClaims.roles && account.idTokenClaims.roles.includes("EstateManager"))
                    return true;
            }
        }
        return false;
    }

    const onHeaderSearch = (e) => {
        try {
            e.preventDefault();

            if (propertyselectvalue && e.target.elements['search'].value.trim()) {

                var squery = propertyselectvalue.value + '_' + e.target.elements['search'].value.trim();

                updateFilters({
                    status: 0, 
                    propertyType: [],
                    propertySubType: [],
                    eventType: [],
                    sslManager: '',
                    name: '',
                    reviewDateStart: null,
                    reviewDateEnd: null,
                    globalSearchQuery: squery,
                    isApplyFilterCall: '',
                    dateIsBlank: false,
                    agency: '',
                    budgetStatus: ''
                });

                
                history.push("/events/0/0/" + squery + "");

            }
            else {
                alert("Please enter property type and search key");
            }
        } catch (err) {
            trackError(err);
        }
    }

    const renderGlobalSearch = () => {
        
        if (location.pathname === '/') {
            return <>
                {isAuthenticated && validateUserRole()?
                <div style={{ justifySelf: 'end', marginLeft: 'auto', display: 'inline-flex'}}>
                    <div style={{ marginTop: '4%' }}>
                        <AutocompleteField
                            name="autocomplete1"
                            placeholder="Choose Options"
                            options={options}
                            label=""
                            onSelect={setPropertyselectvalue}
                        />
                    </div>
                    <HeaderSearch
                            onSubmit={onHeaderSearch}
                        field={{
                            hasButton: true,
                        }}
                        tabBar="max-nav"
                        tabBarSoft
                    />

                    </div>
                    : <div style={{ justifySelf: 'end', marginLeft: 'auto', display: 'inline-flex', alignItems: 'center' }}>
                        {isAuthenticated ?
                            <div>
                                <b>
                                    <span>{accounts[0].name}: </span>
                                    <span className="ln-u-font-weight-regular" style={{ marginLeft: '5px', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => instance.logout()}> Log Out</span>
                                </b>
                            </div>
                            : <></>}
                    </div>}
                </>
        } else {
            return <div style={{ justifySelf: 'end', marginLeft: 'auto', display: 'inline-flex', alignItems: 'center' }}>
                {isAuthenticated ?
                    <div>
                        <b>
                        <span>{accounts[0].name}: </span>
                            <span className="ln-u-font-weight-regular" style={{ marginLeft: '5px', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => instance.logout()}> Log Out</span>
                            </b>
                    </div>
                    : <></>}
            </div>
        }
    } 
  
    return (

        <>
        <Header>
            <Link to='/'>
                <HeaderLogo>
                    <Sainsburys className="header__logo" style={{ verticalAlign: 'middle', height: '24px' }} /> &nbsp; Lease Events
          <span className="ln-u-visually-hidden">Sainsbury's Select logo</span>
                </HeaderLogo>
                </Link>
            {renderGlobalSearch()}
            </Header>
            {maintenanceMessage.length > 0 &&

                <div class="ln-u-bg-color-info ln-u-padding ln-u-text-align-center">
                    <span className="ln-u-color-white">{maintenanceMessage}</span>
                </div>

            }
            </>
    )
}