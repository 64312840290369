import React, { createContext, useReducer } from 'react';
import axios from 'axios';
import AppReducer from './AppReducer';
import { acquireToken } from '../authConfig';
import { trackError } from '../appinsights/applogs';

export const baseUrl = `${window.location.origin}/api`;

const initialState = {
  maintenanceMessage: '',
  events: [],
  filterParams: {
    status: 0,
    propertyType: [],
    propertySubType: [],
    eventType: [],
    sslManager: '',
    name: '',
    reviewDateStart: null,
    reviewDateEnd: null,
    globalSearchQuery: '',
    isApplyFilterCall: '',
    leaseDescTenant: '',
    propertyEvent: 0,
    dateIsBlank: false,
    agency: '',
    budgetStatus: '',
  },
  listingOptions: {
    sortColumn: 'Name',
    sortAscending: true,
    pageSize: 10,
    pageNumber: 1,
  },
  error: null,
  loading: true,
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  const resetListingOptions = () => {
    dispatch({
      type: 'UPDATE_LISTING_OPTIONS',
      payload: {
        sortColumn: 'Name',
        sortAscending: true,
        pageSize: 10,
        pageNumber: 1,
      },
    });
  };

  const setLoading = (isLoading) => {
    dispatch({
      type: 'SET_LOADING',
      payload: isLoading,
    });
  };

  const updateFilters = (filter) => {
    dispatch({
      type: 'UPDATE_FILTERS',
      payload: filter,
    });
  };

  const updateListingOptions = (options) => {
    dispatch({
      type: 'UPDATE_LISTING_OPTIONS',
      payload: options,
    });
  };

  const getEvents = (
    status = 0,
    propertyType = [],
    propertySubType = [],
    eventType = [],
    sslManager = '',
    name = '',
    reviewDateStart = null,
    reviewDateEnd = null,
    searchQuery,
    pageNumber,
    pageSize,
    sortColumn,
    sortOrder,
    eventActionType = '',
    globalSearchQuery = '',
    isApplyFilterCall = '',
    leaseDescTenant = '',
    propertyEvent = 0,
    dateIsBlank = false,
    agency = '',
    budgetStatus = ''
  ) => {
      try {
         
      dispatch({
        type: 'SET_FILTERS',
        payload: {
          status: Number(status),
            propertyType: propertyType.map((pt) => Number(pt)),
          propertySubType: propertySubType.map((pst) => Number(pst)),
          eventType: eventType.map((et) => Number(et)),
          sslManager,
          name,
          reviewDateStart,
          reviewDateEnd,
          globalSearchQuery,
          isApplyFilterCall,
          leaseDescTenant,
          propertyEvent,
          dateIsBlank,
          agency,
          budgetStatus,
        },
      });
      acquireToken().then((response) => {
        (async () => {
          const res = await axios.get(`${baseUrl}/events`, {
            headers: {
              Authorization: 'Bearer ' + response.accessToken,
            },
            params: {
              status: Number(status),
              propertyType: propertyType.join(),
              propertySubType: propertySubType.join(),
              eventType: eventType.join(),
              sslManager,
              name,
              reviewDateStart: reviewDateStart?.format('YYYY-MM-DD'),
              reviewDateEnd: reviewDateEnd?.format('YYYY-MM-DD'),
              searchQuery,
              pageNumber,
              pageSize,
              sortColumn,
              sortOrder,
              eventActionType,
              globalSearchQuery,
              isApplyFilterCall,
              leaseDescTenant,
              propertyEvent,
              dateIsBlank,
              agency,
              budgetStatus,
            },
          });

          dispatch({
            type: 'GET_EVENTS',
            payload: res.data,
          });
        })();
      });
    } catch (err) {
      trackError(err);
      dispatch({
        type: 'EVENTS_ERROR',
        payload: err,
      });
    }
  };
    const updateMessage = (txt) => {
        dispatch({
            type: 'SET_MESSAGE',
            payload: txt,
        });
    };
  const generateFRFZIP = (ids = [], username) => {
    try {
      var requestBody = {
        selectedIds: ids,
        username: username,
      };
      acquireToken().then((response) => {
        (async () => {
          await axios
            .post(`${baseUrl}/file/CreateFRFZIP`, requestBody, {
              headers: {
                Authorization: 'Bearer ' + response.accessToken,
                },
                responseType: 'blob',
            })
              .then((response) => {
                  var today = new Date();
                  var dd = today.getDate();
                  var mm = today.getMonth() + 1;
                  var yyyy = today.getFullYear();
                  var min = today.getMinutes();
                  console.log(response.headers.message);
                  const downloadUrl = window.URL.createObjectURL(
                      new Blob([response.data])
                  );
                  // console.log(response.headers.message);
                  //updateMessage(response.headers.message);
                 
                  const link = document.createElement('a');
                  link.href = downloadUrl;
                  var fname = 'MultipleFRFs_' + yyyy + mm + dd + min + '.zip';
                  link.setAttribute('download', fname);
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                  localStorage.setItem("name", response.headers.message);
                  var delayInMilliseconds = 8000; //4 second

                  setTimeout(function () {
                      localStorage.removeItem("name");
                  }, delayInMilliseconds);
              })
            .catch(function (error) {
              console.log(error);
            });
        })();
      });
      //var delayInMilliseconds = 4000; //4 second

      //setTimeout(function () {
      //  for (var i = 0; i < ids.length; i++) {
      //    document.getElementById(ids[i]).remove();
      //  }
      //}, delayInMilliseconds);

      //window.location.reload(false);
    } catch (err) {
      trackError(err);
      dispatch({
        type: 'EVENTS_ERROR',
        payload: err,
      });
    }
  };

  const checkMaintenanceMessage = () => {
    acquireToken().then((response) => {
      (async () => {
        try {
          const res = await axios.get(`${baseUrl}/home/maintenance`, {
            headers: {
              Authorization: 'Bearer ' + response.accessToken,
            },
          });
          dispatch({
            type: 'SET_MAINTENANCE_MESSAGE',
            payload: res.data,
          });
        } catch (err) {
          trackError(err);
        }
      })();
    });
  };

  return (
    <GlobalContext.Provider
      value={{
        maintenanceMessage: state.maintenanceMessage,
        events: state.events,
        error: state.error,
        filterParams: state.filterParams,
        listingOptions: state.listingOptions,
        loading: state.loading,
        setLoading,
        getEvents,
        updateFilters,
        updateListingOptions,
        resetListingOptions,
        generateFRFZIP,
        checkMaintenanceMessage,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
