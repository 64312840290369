import { getAppInsights } from './TelemetryService';
import { SeverityLevel } from "@microsoft/applicationinsights-web";

export const trackPage = (page) => {
    getAppInsights().trackPageView({ name: page });

};

export const trackError = (err) => {    
    getAppInsights().trackException({
        error: new Error(err),
        severityLevel: SeverityLevel.Error,
    });
};

export const Event = (name, properties) => {
    getAppInsights().trackEvent({
        name: name,
        properties: { properties },
        severityLevel: SeverityLevel.Information,
    });
};
