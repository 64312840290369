import React from 'react';
import { Heading2 } from '@jsluna/typography';
import Page from '../../components/Page';

const Error = () => {
  
  return (
    <Page>
      <Heading2>You don't have permission to access this application, For more details contact administrator.</Heading2>
    </Page>
  );
};

export default Error;
