import React from 'react'
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress'


export default function Progress() {
    // style={{ position: 'relative', zIndex: 2 }}
    return(
        <ProgressIndicator
        className="ln-u-justify-content-center"
        page        
        loading
        preventFocus
      >
        <ProgressSpinner size="large" color="light" />
        Loading...
      </ProgressIndicator>
    )
}