export const GetPropertyData = (id, accessToken) => {
  let url = window.location.origin + '/api/PropertyDetail/' + id;

  return fetch(url, {
    headers: {
      Authorization: 'Bearer ' + accessToken,
    },
  });
};

export const GetFormData = (formData, accessToken) => {
  return fetch(window.location.origin + '/api/file', {
    method: 'POST',
    headers: { Authorization: 'Bearer ' + accessToken },
    body: formData,
  });
};

export const RejectFRF = (id, rejectedStausId, accessToken) => {
  return fetch(
    window.location.origin +
      '/api/PropertyDetail/UpdatePropertyStatus/' +
      id +
      '/' +
      rejectedStausId ,
    {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    }
  );
};

export const AddEntry = (note, accessToken) => {
  return fetch(
    window.location.origin + '/api/PropertyDetail/InsertPropertyNotes',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
      body: JSON.stringify(note),
    }
  );
};

export const AddEntries = (ids, note, accessToken) => {
  return fetch(
    window.location.origin + '/api/PropertyDetail/InsertMultiPropertyNotes',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
      body: JSON.stringify({
        propertyIds: ids,
        notesModel: note,
      }),
    }
  );
};

export const GenerateFRF = (id, accessToken) => {
  return fetch(
    window.location.origin + '/api/file/CreateFRF/' + id ,
    {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    }
  );
};

export const UpdatePropertyStatus = (
  id,
  statusValue,
  accessToken
) => {
  return fetch(
    window.location.origin +
      '/api/PropertyDetail/UpdatePropertyStatus/' +
      id +
      '/' +
      statusValue,
    {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    }
  );
};

export const GetBlobUploaded = (id, accessToken) => {
    return fetch(
        window.location.origin + '/api/file/GetBlobUploaded/' + id,
        {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + accessToken,
            },
        }
    );
};

export const DownloadBlob = (id, directory, blobname, accessToken) => {
    return fetch(
        window.location.origin + '/api/file/DownloadBlob/' + id + '/' + directory + '/' + blobname,
        {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + accessToken,
            },
        }
    );
};

export const SaveBlob = (formData, accessToken) => {
    return fetch(window.location.origin + '/api/file/SaveBlob', {
        method: 'POST',
        headers: { Authorization: 'Bearer ' + accessToken },
        body: formData,
    });
};

export const DeleteBlob = (id, directory, blobname, accessToken) => {
    return fetch(
        window.location.origin + '/api/file/DeleteBlob/' + id + '/' + directory + '/' + blobname,
        {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + accessToken,
            },
        }
    );
};

export const AssignAgencyToEvent = (propertyid, agencyid, accessToken) => {
    return fetch(
        window.location.origin + '/api/PropertyDetail/AssignAgencyToEvent/' + propertyid + ' /' + agencyid,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + accessToken,
            },
        }
    );
};

export const SetBudgetStatus = (propertyid, budgetStatusId, accessToken) => {
    return fetch(
        window.location.origin + '/api/PropertyDetail/SetBudgetStatus/' + propertyid + ' /' + budgetStatusId,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + accessToken,
            },
        }
    );
};

export const SetBudgetAmount = (propertyid, BudgetAmountId, accessToken) => {
    return fetch(
        window.location.origin + '/api/PropertyDetail/AddUpdateBudgetAmount/' + propertyid + ' /' + BudgetAmountId,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + accessToken,
            },
        }
    );
};