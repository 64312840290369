import { PublicClientApplication } from '@azure/msal-browser';
import { trackError } from './appinsights/applogs';

export const msalConfig = {
  auth: {
    clientId: window.envconfig.ClientId,
    authority:
      'https://login.microsoftonline.com/e11fd634-26b5-47f4-8b8c-908e466e9bdf',
    redirectUri: window.location.origin,
    validateAuthority: true,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  //system: {
  //    loggerOptions: {
  //        loggerCallback: (level, message, containsPii) => {
  //            if (containsPii) {
  //                return;
  //            }
  //            switch (level) {
  //                case LogLevel.Error:
  //                    console.error(message);
  //                    return;
  //                case LogLevel.Info:
  //                    console.info(message);
  //                    return;
  //                case LogLevel.Verbose:
  //                    console.debug(message);
  //                    return;
  //                case LogLevel.Warning:
  //                    console.warn(message);
  //                    return;
  //            }
  //        }
  //    }
  //}
};

export const loginRequest = {
  scopes: ['User.Read'],
};

export const apiRequest = {
  scopes: ['api://' + window.envconfig.ClientId + '/user_impersonation'],
  forceRefresh: true,
};

export const appInsightKey = window.envconfig.AppInsightKey;

export const acquireToken = () => {
  const msalInstance = new PublicClientApplication(msalConfig);
  const accounts = msalInstance.getAllAccounts();
  try {
    return msalInstance.acquireTokenSilent({
      ...apiRequest,
      account: accounts.length > 0 ? accounts[0] : accounts,
    });
  } catch (error) {
    console.log(error);
    trackError(error);
    alert('Failed to acquire access token');
  }
};
