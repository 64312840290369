import React,{useRef, useContext, useEffect} from 'react';
import useFetch from '../../helpers/useFetch'
import useFetchForBuild from '../../helpers/useFetchForBuild'
import ProgressIndicator from '../../components/ProgressIndicator'
import Page from '../../components/Page'
import Card from '../../components/Card'
import {links} from '../../helpers/quickLinks'
import styles from './styles.module.css'
import { useHistory } from 'react-router-dom';
import { OutlinedButton } from '@jsluna/button';
import { GridWrapper, GridItem } from "@jsluna/grid";
import { useMsal } from "@azure/msal-react";
import { trackError } from "../../appinsights/applogs";
import { GlobalContext } from '../../context/GlobalState';

export default function Home() {
    const isComponentMounted = useRef(true)
    const { updateFilters, resetListingOptions, checkMaintenanceMessage } = useContext(GlobalContext);
    const history = useHistory();
    const { instance, accounts } = useMsal();
    const { data, loading, error } = useFetch(window.location.origin + '/api/home', isComponentMounted)
    const { builData, buildError, buildLoading } = useFetchForBuild(window.location.origin + '/api/home/GetVersionNumber', isComponentMounted)

    useEffect(() => {
        checkMaintenanceMessage();
        resetListingOptions();
     }, []);


    if(loading) {return <ProgressIndicator />}

    if (error) {
        trackError(error);
        return <div>Error</div>
    }
    if (buildLoading) { return <ProgressIndicator /> }

    if (buildError) {
        trackError(buildError);
        return <div>Error</div>
    }
    
    const displayNonOps = (item) => {
        const status = item.eventStageId === 2 ? 11 : item.statusId
        updateFilters({
            status,
            propertyType: [item.nonOpsId],
            propertySubType: [item.propertySubTypeId],
            eventType: [],
            sslManager: '',
            name: '',
            reviewDateStart: null,
            reviewDateEnd: null,
            globalSearchQuery: '',
            isApplyFilterCall: '',
            leaseDescTenant: '',
            propertyEvent: 0,
            dateIsBlank: false,
            agency: '',
            budgetStatus: ''
        });
        history.push("/events/" + status + "/" + item.nonOpsId + "/" + item.propertySubTypeId);
    }
    const displayOps = (item) => {
        const status = item.eventStageId === 2 ? 11 : item.statusId
        updateFilters({
            status,
            propertyType: [item.opsId],
            propertySubType: [item.propertySubTypeId],
            eventType: [],
            sslManager: '',
            name: '',
            reviewDateStart: null,
            reviewDateEnd: null,
            globalSearchQuery: '',
            isApplyFilterCall: '',
            leaseDescTenant: '',
            propertyEvent: 0,
            dateIsBlank: false,
            agency: '',
            budgetStatus: ''
        });
        history.push("/events/" + status + "/" + item.opsId + "/" + item.propertySubTypeId);
    }
    const quickLinkClick = (status, link) => {
        updateFilters({
            status, 
            propertyType: [],
            propertySubType: [],
            eventType: [],
            sslManager: '',
            name: '',
            reviewDateStart: null,
            reviewDateEnd: null,
            globalSearchQuery: '',
            isApplyFilterCall: '',
            leaseDescTenant: '',
            propertyEvent: 0,
            dateIsBlank: false,
            agency: '',
            budgetStatus: ''
        });
        history.push(link);
    }

    return(
        <Page>
            {/* {console.log(data)}{console.log("builddata:", builData)} */}
            {/*trackPage("Dashboard Page")*/}
            {/* Header Section */}
            <div className='ln-u-display-flex ln-u-justify-content-space-between ln-u-bg-color-orange ln-u-margin-bottom ln-u-padding ln-u-color-white ln-u-font-family-brand ln-u-font-size-display-1'>
                <span>Welcome to the Lease Events Application dashboard</span>
                <code style={{ color: '#F06C00' }}>{global.appVersion}</code>
                <div>
                    <span>You have logged in as {accounts[0].name}: </span>
                    <span className="ln-u-font-size-button" style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => instance.logout()}>Log Out</span>
                </div>
            </div>

            {/*Content section */}
            <GridWrapper>
                <GridItem size="1/2">
                    <Card className="ln-c-card ln-c-card--soft" title={'Upcoming Lease Events'}>
                        <div className="ln-c-table-container">
                            <table className="ln-c-table">
                                <thead className="ln-c-table__header">
                                    <tr className="ln-c-table__row ln-c-table__header-row">
                                        <th className="ln-c-table__header-cell ln-c-table__header-cell--text-align-left" >Property Sub-Type</th>
                                        <th className="ln-c-table__header-cell ln-c-table__header-cell--text-align-left">Ops Total</th>
                                        <th className="ln-c-table__header-cell ln-c-table__header-cell--text-align-left">Non-Ops Total</th>                            
                                    </tr>
                                </thead>
                                <tbody className="ln-c-table__body">
                                    {
                                        data.newProperties.map((item, index) => (
                                            
                                            <tr key={`new_${index}`} className="ln-c-table__row">
                                                <td className="customCol ln-c-table__cell ln-c-table__cell--text-align-left"  >{item.propertySubType}</td>
                                                {
                                                    item.opsCount > 0 ? <td style={{ cursor: "pointer", paddingLeft: '35px' }} className="customCol ln-c-table__cell ln-c-table__cell--text-align-left" title="Click here to view" onClick={() => displayOps(item)}>{item.opsCount}</td>
                                                    : <td style={{  paddingLeft: '35px' }} className="customCol ln-c-table__cell ln-c-table__cell--text-align-left" title="Click here to view">{item.opsCount}</td>
                                                }

                                                {
                                                    item.nonOpsCount > 0 ? <td style={{ cursor: "pointer", paddingLeft: '60px' }} className="customCol ln-c-table__cell ln-c-table__cell--text-align-left" title="Click here to view" onClick={() => displayNonOps(item)}>{item.nonOpsCount}</td>
                                                        : <td style={{ paddingLeft: '60px' }} className="customCol ln-c-table__cell ln-c-table__cell--text-align-left" title="Click here to view">{item.nonOpsCount}</td>
                                                }
                                                        </tr>

                                        ))}</tbody>
                            </table></div>
                        </Card>
                </GridItem>
               
                  <GridItem size="1/2">
                    <Card title={'In-Progress Lease Events'} className="ln-c-card ln-c-card--soft" >
                            <table className="ln-c-table">
                                <thead className="ln-c-table__header">
                                    <tr className="ln-c-table__row ln-c-table__header-row">
                                    <th className="ln-c-table__header-cell ln-c-table__header-cell--text-align-left" >Property Sub-Type</th>
                                    <th className="ln-c-table__header-cell ln-c-table__header-cell--text-align-left">Ops Total</th>
                                    <th className="ln-c-table__header-cell ln-c-table__header-cell--text-align-left">Non-Ops Total</th>
                                  </tr>
                                </thead>
                                <tbody className="ln-c-table__body">
                                    {

                                        data.inProgressProperties.map((item, index) => (
                                            <tr key={`new_${index}`} className="ln-c-table__row" >
                                                <td className="customCol ln-c-table__cell ln-c-table__cell--text-align-left"  >{item.propertySubType}</td>
                                                {
                                                    item.opsCount > 0 ? <td style={{ cursor: "pointer", paddingLeft: '35px' }} className="customCol ln-c-table__cell ln-c-table__cell--text-align-left" title="Click here to view" onClick={() => displayOps(item)}>{item.opsCount}</td>
                                                        : <td style={{ paddingLeft: '35px' }} className="customCol ln-c-table__cell ln-c-table__cell--text-align-left" title="Click here to view">{item.opsCount}</td>
                                                }

                                                {
                                                    item.nonOpsCount > 0 ? <td style={{ cursor: "pointer", paddingLeft: '60px' }} className="customCol ln-c-table__cell ln-c-table__cell--text-align-left" title="Click here to view" onClick={() => displayNonOps(item)}>{item.nonOpsCount}</td>
                                                        : <td style={{ paddingLeft: '60px' }} className="customCol ln-c-table__cell ln-c-table__cell--text-align-left" title="Click here to view">{item.nonOpsCount}</td>
                                                }    </tr>

                                        ))}</tbody>
                            </table>
                        </Card>
                </GridItem> 
                <GridItem size="1" className={styles.qlinks}>
                    <Card title={'Quick Links'} className="ln-c-card ln-c-card--soft" style={{ margin: "30px" }}>
                        <div className={styles.linksContainer}>
                            <div className={styles.quickLinks}>
                                {links.map((item) => (
                                  <OutlinedButton key={item.status} onClick={() => quickLinkClick(item.status, item.link) }>{item.name}</OutlinedButton>
                                ))}
                            </div>
                        </div>
                    </Card>
                </GridItem>
                
            </GridWrapper>
            <div styles={{float:'right'}}><h6> Build version {builData.version} </h6> </div>
        </Page>
        
    )
}