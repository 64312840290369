import { useState, useEffect } from 'react';
import { acquireToken } from '../authConfig';
import { trackError } from '../appinsights/applogs';
export default function useFetchForBuild(url, ref) {
  const [builData, setBuildData] = useState(null);

  const [buildError, setBuildError] = useState(null);
  const [buildLoading, setBuildLoading] = useState(true);
  useEffect(() => {
    acquireToken().then((response) => {
      if (ref.current) {
        (async () => {
          try {
            const res = await fetch(url, {
              headers: {
                Authorization: 'Bearer ' + response.accessToken,
              },
            });
            //console.log("res: ", res);
            if (!res.ok) throw new Error(res.status);
            const resJson = await res.json();
            //console.log("Fetch Result: ", url, resJson)
            setBuildData(resJson);
          } catch (err) {
            //console.log("Fetch Error: ", url, err)
            trackError(err);
            setBuildError(err);
          } finally {
            setBuildLoading(false);
          }
        })();
      }
      return () => {
        ref.current = false;
      };
    });
  }, [url, ref]);
  return { builData, buildError, buildLoading };
}
