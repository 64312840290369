import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import { Accordion, AccordionItem } from '@jsluna/accordion'
import { Card } from '@jsluna/card'
import Page from '../../components/Page';
import style from './style.module.css'
import Fileupload from '../../components/Fileupload'
import { OutlinedButton } from '@jsluna/button'
import { useHistory } from 'react-router-dom'
import ProgressIndicator from '../../components/ProgressIndicator'
import { useMsal } from "@azure/msal-react";
import moment from "moment";
import { SelectField } from '@jsluna/form';
import { acquireToken } from "../../authConfig";
import { trackError } from "../../appinsights/applogs";
import { GetPropertyData, GetFormData, RejectFRF, AddEntry, GenerateFRF, UpdatePropertyStatus, SaveBlob, GetBlobUploaded, DownloadBlob, DeleteBlob, AssignAgencyToEvent, SetBudgetStatus, SetBudgetAmount } from "./api";
import { GlobalContext } from '../../context/GlobalState';
import { Delete } from '@jsluna/icons';

export default function Details() {

    const { id } = useParams();
    const history = useHistory();
    const { filterParams } = useContext(GlobalContext);
    const [selectedFile, setSelectedFile] = useState();
    const [blobSelectedFile, setblobSelectedFile] = useState();
    //const [isFileSelected, setIsFileSelected] = useState(false);
    const [eventDetails, setEventDetails] = useState();
    const [collapseExpand, setCollapseExpand] = useState();
    const [isFRFCreate, setisFRFCreate] = useState(false);
    const [fileLoading, setFileLoading] = useState(false);
    const [isFRFUpload, setisFRFUpload] = useState(false);
    const [noteCollection, setnoteCollection] = useState();
    const [addNote, setaddNote] = useState('');
    const [statusValue, setStatusValue] = useState(0);
    const [statusText, setStatusText] = useState('FRF Document Uploaded');
    const [statusStorage, setStatusStorage] = useState('');
    const { accounts } = useMsal();

    //const [callForId, setcallForId] = useState(false);
    const [getSet, setAlreadyCompleted] = useState(false);
    const [isStatusUpdate, setPropertyStatus] = useState(false);
    const [isMissingFile, setFileUpload] = useState(false);
    const [newNotesAdded, setNewNotes] = useState(false);
    const [erroMessage, setErrorText] = useState('');
    const [blobCollection, setBlobCollection] = useState([]);
    //const [token, settoken] = useState(() => () => { let tkn = ''; acquireToken().then((response) => { tkn = response.accessToken; }); return tkn; });  //acquireToken().then((response) => { return response.accessToken; }));
    const [statusOptions, setStatusOptions] = useState([]);
    const [budgetstatusOptions, setBudgetStatusOptions] = useState([]);
    const [agencyOptions, setAgencyOptions] = useState([]);
    const [budgetStatusValue, setBudgetStatusValue] = useState("");
    const [agencyValue, setAgencyValue] = useState("");
    const [budgetstatusribbon, setBudgetStatusribbon] = useState(false);
    const [agencyupdateribbon, setAgencyUpdate] = useState(false);

    const [budgetAmount, setBudgetAmount] = useState();
    const [budgetamountribbon, setbudgetamountribbon] = useState(false);

    const GetData = (formData) => {
        if (formData) {
            acquireToken().then((response) => {
                var res = GetFormData(formData, response.accessToken);
                res.then(response => response.json())
                    .then(data => {
                        if (data.errorMessage) {
                            setErrorText(data.errorMessage);
                        }
                        else {
                            setEventDetails(data); setisFRFCreate(data.isFRFCreated); setisFRFUpload(data.isFRFUploded); setStatusText(data.status); setErrorText(data.errorMessage);

                            const agencylist = data.agencyList.map((s) => {
                                return { label: s.name, value: s.id };
                            });
                            setAgencyOptions(agencylist);

                            const budgetstatus = data.budgetStatusList.map((s) => {
                                return { label: s.name, value: s.id };
                            });
                            setBudgetStatusOptions(budgetstatus);
                            //console.log(data);
                            if (data.propertyDetails.selectedAgencyId > 0)
                                setAgencyValue(data.selectedAgencyId);

                            if (data.propertyDetails.selectedBudgetStatusId > 0)
                                setBudgetStatusValue(data.selectedBudgetStatusId);

                            if (data.budgetRent)
                                setBudgetAmount(data.budgetRent);

                            loadBlob();
                        }
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                        trackError(error);
                    });
            });


        }
        else {
            acquireToken().then((response) => {
                var res = GetPropertyData(id, response.accessToken);
                res.then(response => response.json())
                    .then(data => {
                        setEventDetails(data);
                        setisFRFCreate(data.isFRFCreated);
                        setisFRFUpload(data.isFRFUploded);
                        setStatusText(data.status);
                        console.log(statusText);
                        setErrorText(data.errorMessage);
                        
                        const agencylist = data.agencyList.map((s) => {
                            return { label: s.name, value: s.id };
                        });
                        setAgencyOptions(agencylist);

                        const budgetstatus = data.budgetStatusList.map((s) => {
                            return { label: s.name, value: s.id };
                        });
                        setBudgetStatusOptions(budgetstatus);
                        
                        if (data.selectedAgencyId > 0)
                            setAgencyValue(data.selectedAgencyId);

                        if (data.selectedBudgetStatusId > 0)
                            setBudgetStatusValue(data.selectedBudgetStatusId);

                        if (data.budgetRent)
                            setBudgetAmount(data.budgetRent);

                    })
                    .catch((error) => {
                        console.error('Error:', error);
                        trackError(error);
                    });
                
                var statusesRes = axios.get(
                    `${window.location.origin}/api/selectlist/Statuses`,
                    {
                        headers: {
                        Authorization: 'Bearer ' + response.accessToken,
                        },
                    }
                );

                statusesRes.then(response => {
                    const statuses = response.data
                        .filter((s) => s.value !== '10')
                        .filter((s) => s.value !== '11')
                        .filter((s) => s.value !== '1')
                        .map((s) => {
                            return { label: s.description, value: s.value };
                        });

                        setStatusOptions(statuses);
                    }
                );

              
            });
        }
    }

    useEffect(() => {

        if (!getSet) {
            setAlreadyCompleted(true);
            GetData();
            loadBlob();
        }

    });

    const rejectFRFClick = async (event) => {
        event.preventDefault();
        var rejectedStausId = 10;
        acquireToken().then((response) => {
            var res = RejectFRF(id, rejectedStausId, response.accessToken);
            res.then(response => {
                if (response.ok) {
                    var delBlob = blobCollection.filter(x => x.key == 2);
                    if (delBlob.length) {
                        deleteFileFromBlob(delBlob[0].directory, delBlob[0].filename);
                    }
                    setisFRFUpload(false);
                    setCollapseExpand(false);
                    setAlreadyCompleted(false);
                    setSelectedFile(null);
                    //window.location.reload(false);

                    //console.log('update success');
                    //setPropertyStatus(true);
                    //setStatusText(statusStorage);
                    //alert('Update Successful');
                }
            })
                .catch((error) => {
                    console.error('Error:', error);
                    trackError(error);
                });
        });
    }

    const addEntryClick = async (event) => {
        event.preventDefault();
        if (addNote.trim()) {
            //const dtformat = "YYYY-MM-DD";
            var dt = new Date();
            const note = {
                addedBy: accounts[0].name,
                addedDate: moment(dt),
                note: addNote,
                fk_PropertyId: parseInt(id, 10),
            }
            acquireToken().then((response) => {
                var res = AddEntry(note, response.accessToken)
                res.then((response) => {
                    if (response.status === 200) {

                        var notes = noteCollection ? noteCollection : eventDetails && eventDetails.notesData ? eventDetails.notesData : "";
                        setnoteCollection(noteCollection => [...notes, note]);
                        setaddNote('');
                        setNewNotes(true);
                    }
                })
                    .catch((err) => {
                        trackError(err);
                        console.log(err);
                    });
            });
        }
    };

    const changeHandler = (event) => {
        setSelectedFile(null);
        const file = event.target.files[0];

        if (file !== null && file !== undefined) {
            const ext = file.name.split('.').pop();

            if (isValidExtForFRF(ext)) {
                setSelectedFile(file);
                //setIsFileSelected(true);
                setErrorText("");
            } else {
               event.preventDefault();
                event.target.value = "";
                setErrorText("Invalid file type selected for FRF Upload, please select doc or docx file");
           }
        }
    };

    const isValidExtForFRF = ext => {
        return ext === "doc" || ext === "docx";
    }

    const handleSubmission = () => {
        if (selectedFile == null) {
            setErrorText("Please select the FRF document for upload.");
        }

        else {
            const formData = new FormData();
            if (selectedFile) {
                formData.append('formFile', selectedFile);
                formData.append('Id', id);
                //formData.append('addedBy', accounts[0].name);
                GetData(formData);
            }
            else {
                setFileUpload(true);
            }
        }
    };

    const handleCollapseExpand = (e) => {
        if (collapseExpand === undefined || collapseExpand === false) {
            e.target.innerText = "Collapse All";
            setCollapseExpand(true);
        } else {
            e.target.innerText = "Expand All";
            setCollapseExpand(false);
        }
    };

    const generateFRF = (e) => {
        setFileLoading(true);
        acquireToken().then((response) => {
            var res = GenerateFRF(id, response.accessToken);
            res.then(response => {
                if (response.ok) {
                    setisFRFCreate(true);
                    var date = moment(eventDetails.propertyDetails.reviewDate).format("DD-MM-YYYY");
                    const filename = "FRF-" + eventDetails.propertyDetails.propertyName.trim().replaceAll(",", "_").replaceAll(" ", "_").replaceAll("/", "").replaceAll("\\", "") + ("_") + eventDetails.propertyDetails.leaseReference.trim().replaceAll(",", "_").replaceAll(" ", "_").replaceAll("/", "").replaceAll("\\", "") + ("_") + date + ".docx";
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = filename;
                        a.click();
                    });
                    setFileLoading(false);
                    loadBlob();
                    //  window.location.reload(false);
                }
            });
        });
    };

    const updatePropertyStatus = (e) => {
        if (statusValue) {

            acquireToken().then((response) => {
                var res = UpdatePropertyStatus(id, statusValue, response.accessToken);

                res.then(response => {
                    if (response.ok) {

                        console.log('update success');
                        setPropertyStatus(true);
                        //const dtformat = "YYYY-MM-DD";
                        var dt = new Date();
                        let from = statusText;
                        let to = statusStorage;
                        let noteText = "The status has been changed from " + from + " to " + to;
                        const note = {
                            addedBy: accounts[0].name,
                            addedDate: moment(dt),
                            note: noteText,

                        }
                        setStatusText(statusStorage);
                        var notes = noteCollection ? noteCollection : eventDetails && eventDetails.notesData ? eventDetails.notesData : "";
                        setnoteCollection(noteCollection => [...notes, note]);
                        setNewNotes(true);
                        //alert('Update Successful');
                    }
                })
                    .catch((error) => {
                        console.error('Error:', error);
                        trackError(error);
                    });
            });
        }
        else {
            alert('Please Choose Status');
        }
    }

    const agencyChangeHandler = (e) => {
        let agencyval = e.target.value;

        if (!agencyval) {
            agencyval = 0;
        }

        setAgencyValue(agencyval);
        setFileLoading(true);
        acquireToken().then((response) => {
            var res = AssignAgencyToEvent(id, agencyval, response.accessToken);
            res.then((response) => {
                if (response.status === 200) {
                    setFileLoading(false);
                    setAgencyUpdate(true);
                    setTimeout(function () {
                        setAgencyUpdate(false);
                    }, 2000);
                }
            }).catch((error) => {
                setFileLoading(false);
                console.error('Error:', error);
                trackError(error);
            });
        });

    }

    const budgetStatusChangeHandler = (e) => {
        let budgetstateval = e.target.value;

        if (!budgetstateval) {
            budgetstateval = 0;
        }

        setBudgetStatusValue(budgetstateval);
        setFileLoading(true);
        acquireToken().then((response) => {
            var res = SetBudgetStatus(id, budgetstateval, response.accessToken);
            res.then((response) => {
                if (response.status === 200) {
                    setFileLoading(false);
                    setBudgetStatusribbon(true);
                    setTimeout(function () {
                        setBudgetStatusribbon(false);
                    }, 2000);
                }
            }).catch((error) => {
                setFileLoading(false)
                console.error('Error:', error);
                trackError(error);
            });
        });

    }

    const statusChangeHandler = (e) => {

        setStatusValue(e.target.value);
        var status = e.nativeEvent.target.selectedOptions[0].label;
        setStatusStorage(status);

    }

    const renderUpdateState = () => {
        return (
            <div style={{ display: 'inline-flex', float: 'right', marginBottom: '-25px', }}>

                <SelectField
                    placeholder="Please Choose Status"
                    name='status'
                    options={statusOptions}
                    value={statusValue}
                    onChange={statusChangeHandler}
                />

                <OutlinedButton style={{ marginLeft: '5px', height: 'fit-content' }} onClick={updatePropertyStatus} >Update Status</OutlinedButton>
            </div>

        );
    }

    const isValidExtBlob = ext => {
        return ext === "doc" || ext === "docx" || ext === "pdf" || ext === "xlsx" || ext === "xls";
    }

    const blobChangeHandler = (event) => {
        setblobSelectedFile(null);
        const file = event.target.files[0];
        const ext = file.name.split('.').pop();

        if (file !== null && file !== undefined) {
            if (isValidExtBlob(ext)) {
                setSelectedFile(file);
                //setIsFileSelected(true);
                setErrorText("");
                if (file) {
                    if ((file.size / 1024 / 1024) < 25) {
                        setblobSelectedFile(file);
                    }
                    else {
                        event.target.value = "";
                        setblobSelectedFile(null);
                        alert("File size should be less than 25 MB");
                    }
                }
            }
            else {
                event.preventDefault();
                event.target.value = "";
                setErrorText("Invalid file type selected for FRF Upload, please select a doc, docx, pdf, xlsx or xls file.");
            }
        }
    };

    const downloadBlob = (blob) => {
        setFileLoading(true);
        var filename = blob.displayName;
        acquireToken().then((response) => {
            var res = DownloadBlob(id, blob.directory, blob.filename, response.accessToken);
            res.then(response => {
                if (response.ok) {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = filename;
                        a.click();
                    });
                    setFileLoading(false);
                }
            }).catch((error) => {
                console.error('Error:', error);
                setFileLoading(false);
            });
        });
    };

    const saveToBlob = () => {
        const formData = new FormData();
        if (blobSelectedFile) {
            formData.append('formFile', blobSelectedFile);
            formData.append('Id', id);
            //formData.append('addedBy', accounts[0].name);
            setFileLoading(true);

            acquireToken().then((response) => {
                var res = SaveBlob(formData, response.accessToken);
                res.then(response => response.json())
                    .then(data => {
                        setFileLoading(false);
                        setBlobCollection(data);
                        setblobSelectedFile(null);
                    })
                    .catch((error) => {
                        setFileLoading(false);
                        console.error('Error:', error);
                        trackError(error);
                    });
            });
        }
    };

    const loadBlob = () => {
        setFileLoading(true);
        acquireToken().then((response) => {
            var res = GetBlobUploaded(id, response.accessToken);
            res.then(response => response.json())
                .then(data => {
                    setBlobCollection(data);
                    setFileLoading(false);
                })
                .catch((error) => {
                    setFileLoading(false);
                    console.error('Error:', error);
                    trackError(error);
                });
        });
    };

    const deleteBlob = (blob) => {
        const res = window.confirm("Do you really want to Delete the file?. Delete action can't be undo.");
        if (res == true) {
            setFileLoading(true);
            deleteFileFromBlob(blob.directory, blob.filename);
        }
    }

    const deleteFileFromBlob = (directory, filename) => {
        acquireToken().then((response) => {
            var res = DeleteBlob(id, directory, filename, response.accessToken);
            res.then(response => response.json())
                .then(data => {
                    setBlobCollection(data);
                    setFileLoading(false);
                })
                .catch((error) => {
                    setFileLoading(false);
                    console.error('Error:', error);
                    trackError(error);
                });
        });
    }

    const budgetAmountChangeHandler = (e) => {
        let val = e.target.value;
        val = val.replace(/([^0-9.]+)/, "");
        val = val.replace(/^(0|\.)/, "");
        const match = /(\d{0,15})[^.]*((?:\.\d{0,2})?)/g.exec(val);
        const value = match[1] + match[2];
        e.target.value = value;
        if (val.length > 0) {
            e.target.value = Number(value).toFixed(2);
            setBudgetAmount(Number(value).toFixed(2));
        }
    };

    const submitBudgetAmount = (e) => {
        if (budgetAmount) {
            setFileLoading(true);
            acquireToken().then((response) => {
                var res = SetBudgetAmount(id, budgetAmount, response.accessToken);
                res.then((response) => {
                    if (response.status === 200) {
                        setFileLoading(false);
                        setbudgetamountribbon(true);
                        setTimeout(function () {
                            setbudgetamountribbon(false);
                        }, 2000);
                    }
                }).catch((error) => {
                    setFileLoading(false)
                    console.error('Error:', error);
                    trackError(error);
                });
            })
        }
    };

    const renderAttachments = () => {
        let uniquekey = [...new Set(blobCollection.map(item => item.key))];
        return (
            <>
                <div>
                    <Fileupload onClick={() => saveToBlob()} onChange={blobChangeHandler} acceptExtension=".doc, .docx, .pdf, .xls, .xlsx" name="Upload" />
                </div >
                <br/>
                {
                    (uniquekey.length > 0) ?
                        uniquekey.map(index => (
                            <>
                                {(index == 1) ? <span>FRF Template</span> : (index == 2) ? <span>Uploaded FRF</span> : <span>Attachments</span>}
                                <ul>
                                    {(blobCollection.filter(x => x.key == index)).map(item => (

                                        <b><li style={{ width: 'fit-content' }}>
                                            <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => downloadBlob(item)} >{item.displayName} </span>
                                            {(item.isDelete) ?
                                                <Delete style={{ cursor: 'pointer' }} size="large" className="ln-u-margin-left ln-u-margin-bottom" onClick={() => deleteBlob(item)} />
                                                : <></>
                                            }
                                        </li></b>
                                    ))
                                    }
                                </ul>
                            </>
                        )) : <span>No attachments.</span>
                }
            </>
        );
    }

    return (
        <Page>
                    <div className={style.topbar + ' ln-u-margin-bottom'}>
                <h5>Current status :
                    {!isFRFCreate ? <span>{statusText}</span> : isFRFCreate && !isFRFUpload ? <span>{statusText }</span> : isFRFCreate && isFRFUpload ? <span>{statusText}</span> : ""}
               
               
               <span> for event ref.</span>
                     
                {eventDetails && eventDetails.propertyDetails.leaseReference ? eventDetails.propertyDetails.leaseReference : ""}
                </h5>
                    </div>
            
            <Card className={style.card + ' ln-u-border'} style={{ width: '100%' }}>
                <OutlinedButton onClick={() => { history.goBack() }}>Back</OutlinedButton>
                {renderUpdateState()}
                {(isFRFUpload) ?
                    ""
                    : (isFRFCreate) ?
                        <div style={{ display: 'inline-block', float: 'right' }}>

                            <Fileupload onClick={() => handleSubmission()} onChange={changeHandler} acceptExtension=".doc, .docx" name="Upload FRF" />
                            <OutlinedButton style={{ marginRight: '5px' }} onClick={generateFRF}>Download FRF</OutlinedButton>
                        </div>
                        : <OutlinedButton style={{ marginRight: '5px', float: 'right' }} onClick={generateFRF}>Create FRF</OutlinedButton>
                }

            </Card>
            
            <div className="ln-u-margin-top*3 ln-u-margin-left">
            {isFRFUpload ? 
                    <a className={"ln-c-button--text " + `${!isFRFUpload ? style.disableevents : ""}`} style={{ cursor: 'pointer' }} onClick={handleCollapseExpand}>Expand All</a>
                : ''
                }
            </div> 
            <div>
                
                {/* FRF upload confirmation */}
                {(eventDetails && eventDetails.isConfirmation) ? <div className={style.rrsuccessalert}>The FRF document has been uploaded successfully, please review it and in case of any inconsistancies <a href="#" onClick={rejectFRFClick}>click here </a> to Reject It.</div>: ''
                }
                {/* Status Update Message */}
                {(isStatusUpdate) ? <div className={style.rrsuccessalert}>The status has been updated successfully.</div> : ''
                }
                {/* File Not selected Message */}
                {(isMissingFile) ? <div className={style.rralert}>Please select the FRF document for upload.</div> : ''
                }
                {erroMessage && erroMessage !== '' ? <div className={style.rralert}>{erroMessage}</div> : ''}
            </div>

            <Accordion multipleOpen={true} titleElement="h3">
                <AccordionItem defaultOpen={collapseExpand === undefined ? true : collapseExpand} id="accordion-item-1" className={style.accordstyle + ' ln-u-border'} title="Property Details" >
                    <div className="ln-c-table-container">
                        <table className={style.table + ' ln-c-table'}>
                            <tbody className="ln-c-table__body">
                                {(eventDetails && eventDetails.propertyRepresntative) ?
                                    <tr>
                                        <td className={style.lightgray}>Sainbury's argos report</td>
                                        <td colSpan="3">{eventDetails.propertyRepresntative}</td>
                                    </tr>
                                    : <></>
                                }
                                <tr>
                                    <td className={style.lightgray} style={{ width: '25%' }}>Lease Reference</td>
                                    <td style={{ width: '25%' }}>{eventDetails && eventDetails.propertyDetails.leaseReference ? eventDetails.propertyDetails.leaseReference : ""}</td>
                                    <td className={style.lightgray} style={{ width: '25%' }}>Property Reference (Horizon PIN)</td>
                                    <td style={{ width: '25%' }}>{eventDetails && eventDetails.propertyDetails.pin ? eventDetails.propertyDetails.pin : ""}</td>
                                </tr>
                                <tr>
                                    <td className={style.lightgray} style={{ width: '25%' }}>Property Name</td>
                                    <td style={{ width: '25%' }}>{eventDetails ? eventDetails.propertyDetails.propertyName : ""}</td>
                                    <td className={style.lightgray} style={{ width: '25%' }}>Store Number</td>
                                    <td style={{ width: '25%' }}>{eventDetails ? eventDetails.propertyDetails.storeNumber : ""}</td>
                                </tr>
                                <tr>
                                    <td className={style.lightgray}>Address Line 1</td>
                                    <td colSpan="3">{eventDetails ? eventDetails.propertyDetails.addressLine1 : ""}</td>
                                </tr>
                                <tr>
                                    <td className={style.lightgray}>Address Line 2</td>
                                    <td colSpan="3">{eventDetails ? eventDetails.propertyDetails.addressLine2 : ""}</td>
                                </tr>
                                <tr>
                                    <td className={style.lightgray}>City</td>
                                    <td>{eventDetails ? eventDetails.propertyDetails.city : ""}</td>
                                    <td className={style.lightgray}>Post Code</td>
                                    <td>{eventDetails ? eventDetails.propertyDetails.postcode : ""}</td>
                                </tr>
                                <tr>
                                    <td className={style.lightgray}>Region</td>
                                    <td>{eventDetails ? eventDetails.propertyDetails.region : ""}</td>
                                    <td className={style.lightgray}>Zone</td>
                                    <td>{eventDetails ? eventDetails.propertyDetails.zone : ""}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br />

                    {budgetstatusribbon ? <div className={style.rrsuccessalert}>The budget status has been updated successfully.</div> : ""}
                    {agencyupdateribbon ? <div className={style.rrsuccessalert}>The agency has been updated successfully.</div> : ""}
                    {budgetamountribbon ? <div className={style.rrsuccessalert}> The Budget Amount has been updated successfully </div> : ""}
                    <div className="ln-c-table-container">
                        <table className={style.table + ' ln-c-table'}>
                            <tbody className="ln-c-table__body">
                                    <tr>
                                        <td style={{ width: '25%' }} className={style.lightgray}>Agency</td>

                                        <td style={{ width: '25%' }}>{
                                            <div className={style.marginbotton25}>
                                                <SelectField
                                                    className={(!isFRFUpload) ? '' : style.disablestyle}
                                                    style={{ minHeight: '30px' }}
                                                    placeholder="Not Assigned"
                                                    name='agency'
                                                    options={agencyOptions}
                                                    value={agencyValue}
                                                    onChange={agencyChangeHandler}
                                                   
                                                />
                                            </div>
                                        }
                                        </td>
                                        <td style={{ width: '25%' }} className={style.lightgray}>Budget Status</td>
                                        <td style={{ width: '25%' }}>{
                                            <div className={style.marginbotton25}>
                                                <SelectField
                                                    className={(!isFRFUpload) ? '' : style.disablestyle}
                                                    style={{ minHeight: '30px' }}
                                                    placeholder="Not Required"
                                                    name='budgetstatus'
                                                    options={budgetstatusOptions}
                                                    value={budgetStatusValue}
                                                    onChange={budgetStatusChangeHandler}
                                                />
                                            </div>
                                        }
                                        </td>
                                </tr>
                                <tr>
                                <td style={{ width: '25%' }} className={style.lightgray}>Budget Rent</td>
                                <td style={{ width: '25%' }}>{
                                    <div class="ln-c-input-group">
                                        <input
                                            className={(!isFRFUpload) ? '' : style.disablestyle}
                                            style={{ minHeight: '30px' }}
                                            placeholder="Enter Budget Amount"
                                            name='budgetrent'
                                            type="number"
                                            class="ln-c-text-input ln-c-input-group__control"
                                            pattern="[+-]?\d+(?:[.,]\d+)?"
                                                value={budgetAmount ? Number(budgetAmount).toFixed(2) : budgetAmount}
                                            onChange={budgetAmountChangeHandler}
                                        />
                                    </div>
                                }

                                    <button
                                        className={(!isFRFUpload) ? '' : style.disablestyle}
                                        style={{ marginTop: '10px' }}
                                        class="ln-c-button ln-c-button--filled ln-c-button--full"
                                        type="submit"
                                        onClick={(e) => submitBudgetAmount(e)}
                                    >
                                        Save Budget
                                    </button>
                                    </td>
                                 </tr>
                            </tbody>
                        </table>
                    </div>
                </AccordionItem>

                {(!isFRFUpload) ?
                    <AccordionItem defaultOpen={collapseExpand} id="accordion-item-11" title="Notes" className={style.accordstyle + ' ln-u-border'} >
                        <div style={{ overflowY: 'auto' }}>
                            {newNotesAdded && noteCollection && 
                                (noteCollection).map(item => (
                                    <div style={{ marginBottom: '12px' }}>
                                        <div><b>  {item.addedBy} </b> at {moment(item.addedDate).format("DD-MM-YYYY HH:mm:ss")} </div>
                                        <div>{item.note}</div>
                                    </div>
                                ))
                            }
                            {newNotesAdded === false && eventDetails && eventDetails.notesData &&
                                (eventDetails.notesData).map(item => (
                                    <div style={{ marginBottom: '12px' }}>
                                        <div><b>  {item.addedBy} </b> at {moment(item.addedDate).format("DD-MM-YYYY HH:mm:ss")} </div>
                                        <div>{item.note}</div>
                                    </div>
                                ))
                            }
                           
                            
                        </div>
                        <div>
                            <textarea rows="6" value={addNote} style={{ width: '100%', border: '1px solid #adadad' }} onChange={(e) => { setaddNote(e.target.value); }} >
                            </textarea>
                            <OutlinedButton onClick={addEntryClick}>Add Note</OutlinedButton>
                        </div>
                    </AccordionItem>
                    : <></>
                }

                {(!isFRFUpload) ?
                    <AccordionItem defaultOpen={collapseExpand} id="accordion-item-12" title="Attachments" className={style.accordstyle + ' ln-u-border'} >
                        {renderAttachments()}
                    </AccordionItem>
                    : <></>
                }

                <AccordionItem defaultOpen={collapseExpand} id="accordion-item-2" title="Particulars"
                    className={`${style.accordstyle} ln-u-border ${!isFRFUpload ? style.disableevents : ""}`} >
                    <div className="ln-c-table-container">
                        <table className={style.table + ' ln-c-table'}>
                            <tbody className="ln-c-table__body">
                                <tr>
                                    <td className={style.lightgray} style={{ width: '25%' }}>Property Type :</td>
                                    <td style={{ width: '25%' }}>{eventDetails && eventDetails.particulars ? eventDetails.particulars.propertyType : ""}</td>
                                    <td className={style.lightgray} style={{ width: '25%' }}>Area(SQFT) :</td>
                                    <td style={{ width: '25%' }}>{eventDetails && eventDetails.particulars ? eventDetails.particulars.area : ""}</td>
                                </tr>
                                <tr>
                                    <td className={style.lightgray} style={{ width: '25%' }}>Format :</td>
                                    <td style={{ width: '25%' }}>{eventDetails && eventDetails.particulars ? eventDetails.particulars.format : ""}</td>
                                    <td className={style.lightgray} style={{ width: '25%' }}>Measure :</td>
                                    <td style={{ width: '25%' }}>{eventDetails && eventDetails.particulars ? eventDetails.particulars.measure : ""}</td>
                                </tr>
                                <tr>
                                    <td className={style.lightgray} style={{ width: '25%' }}>Lease Event Date :</td>
                                    <td style={{ width: '25%' }}>{eventDetails && eventDetails.particulars ? eventDetails.particulars.leaseEventDate : ""}</td>
                                    <td className={style.lightgray} style={{ width: '25%' }}>% at Ground Level :</td>
                                    <td style={{ width: '25%' }}>{eventDetails && eventDetails.particulars ? eventDetails.particulars.pctGroundLevel : ""}</td>
                                </tr>
                                <tr>
                                    <td className={style.lightgray}>Lease Event Type :</td>
                                    <td colSpan="3">{eventDetails && eventDetails.particulars ? eventDetails.particulars.leaseEventType : ""}</td>
                                </tr>
                                <tr>
                                    <td className={style.lightgray}>Parent Landlord :</td>
                                    <td colSpan="3">{eventDetails && eventDetails.particulars ? eventDetails.particulars.parentLandlord : ""}</td>
                                </tr>
                                <tr>
                                    <td className={style.lightgray}>Landlord :</td>
                                    <td colSpan="3">{eventDetails && eventDetails.particulars ? eventDetails.particulars.landlord : ""}</td>
                                </tr>
                                <tr>
                                    <td className={style.lightgray}>Tenant :</td>
                                    <td colSpan="3">{eventDetails && eventDetails.particulars ? eventDetails.particulars.tenant : ""}</td>
                                </tr>
                                <tr>
                                    <td className={style.lightgray}>Landlord/Tenant Agent :</td>
                                    <td colSpan="3">{eventDetails && eventDetails.particulars ? eventDetails.particulars.landlordOrTenantAgent : ""}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

            </AccordionItem>
                <AccordionItem defaultOpen={collapseExpand} id="accordion-item-3" title="Proposed Transaction" className={`${style.accordstyle} ln-u-border ${!isFRFUpload ? style.disableevents : ""}`} >
                    <div className="ln-c-table-container">
                        <table className={style.table + ' ln-c-table'}>
                            <thead className="ln-c-table__header">
                                <tr className="ln-c-table__row ln-c-table__header-row">
                                    <th className="ln-c-table__header-cell"></th>
                                    <th className="ln-c-table__header-cell">£ / Annum</th>
                                    <th className="ln-c-table__header-cell">Rate / ITZA PSF</th>
                                    <th className="ln-c-table__header-cell">% Increase</th>
                                    <th className="ln-c-table__header-cell">CAGR</th>
                                    <th className="ln-c-table__header-cell">Basis</th>
                                </tr>
                            </thead>
                            <tbody className="ln-c-table__body">
                                <tr>
                                    <td style={{ width: '15%' }} className={style.lightgray}>Current Rent</td>
                                    <td style={{ width: '17%' }}>{eventDetails && eventDetails.proposedTransactions ? eventDetails.proposedTransactions.currentRentAnnum : ""}</td>
                                    <td style={{ width: '17%' }}>{eventDetails && eventDetails.proposedTransactions ? eventDetails.proposedTransactions.currentRentRatePSF : ""}</td>
                                    <td style={{ width: '17%' }}>{eventDetails && eventDetails.proposedTransactions ? eventDetails.proposedTransactions.currentRentIncrease : ""}</td>
                                    <td style={{ width: '17%' }}>{eventDetails && eventDetails.proposedTransactions ? eventDetails.proposedTransactions.currentRentCAGR : ""}</td>
                                    <td style={{ width: '17%' }}>{eventDetails && eventDetails.proposedTransactions ? eventDetails.proposedTransactions.currentRentBasis : ""}</td>
                                </tr>  
                                <tr>
                                    <td style={{ width: '15%' }} className={style.lightgray}>Budget Rent</td>
                                    <td style={{ width: '17%' }}>{eventDetails && eventDetails.proposedTransactions ? eventDetails.proposedTransactions.budgetRentAnnum : ""}</td>
                                    <td style={{ width: '17%' }}>{eventDetails && eventDetails.proposedTransactions ? eventDetails.proposedTransactions.budgetRentRatePSF : ""}</td>
                                    <td style={{ width: '17%' }}>{eventDetails && eventDetails.proposedTransactions ? eventDetails.proposedTransactions.budgetRentIncrease : ""}</td>
                                    <td style={{ width: '17%' }}>{eventDetails && eventDetails.proposedTransactions ? eventDetails.proposedTransactions.budgetRentCAGR : ""}</td>
                                    <td style={{ width: '17%' }}>{eventDetails && eventDetails.proposedTransactions ? eventDetails.proposedTransactions.budgetRentBasis : ""}</td>
                                </tr>  
                                <tr>
                                    <td style={{ width: '15%' }} className={style.lightgray}>Proposed Rent</td>
                                    <td style={{ width: '17%' }}>{eventDetails && eventDetails.proposedTransactions ? eventDetails.proposedTransactions.purposedRentAnnum : ""}</td>
                                    <td style={{ width: '17%' }}>{eventDetails && eventDetails.proposedTransactions ? eventDetails.proposedTransactions.purposedRentRatePSF : ""}</td>
                                    <td style={{ width: '17%' }}>{eventDetails && eventDetails.proposedTransactions ? eventDetails.proposedTransactions.purposedRentIncrease : ""}</td>
                                    <td style={{ width: '17%' }}>{eventDetails && eventDetails.proposedTransactions ? eventDetails.proposedTransactions.purposedRentCAGR : ""}</td>
                                    <td style={{ width: '17%' }}>{eventDetails && eventDetails.proposedTransactions ? eventDetails.proposedTransactions.purposedRentBasis : ""}</td>
                                </tr>  
                                <tr>
                                    <td style={{ width: '15%' }} className={style.lightgray}>Variance to Budget</td>
                                    <td style={{ width: '17%' }}>{eventDetails && eventDetails.proposedTransactions ? eventDetails.proposedTransactions.varianceToBudgetRentAnnum : ""}</td>
                                    <td style={{ width: '17%' }}>{eventDetails && eventDetails.proposedTransactions ? eventDetails.proposedTransactions.varianceToBudgetRentRatePSF : ""}</td>
                                    <td style={{ width: '17%' }}>{eventDetails && eventDetails.proposedTransactions ? eventDetails.proposedTransactions.varianceToBudgetRentIncrease : ""}</td>
                                    <td style={{ width: '17%' }}>{eventDetails && eventDetails.proposedTransactions ? eventDetails.proposedTransactions.varianceToBudgetRentCAGR : ""}</td>
                                    <td style={{ width: '17%' }}>{eventDetails && eventDetails.proposedTransactions ? eventDetails.proposedTransactions.varianceToBudgetRentBasis : ""}</td>
                                </tr>  
                                <tr>
                                    <td style={{ width: '15%' }} className={style.lightgray}>ERV</td>
                                    <td style={{ width: '17%' }}>{eventDetails && eventDetails.proposedTransactions ? eventDetails.proposedTransactions.eRVAnnum : ""}</td>
                                    <td style={{ width: '17%' }}>{eventDetails && eventDetails.proposedTransactions ? eventDetails.proposedTransactions.eRVRatePSF : ""}</td>
                                    <td style={{ width: '17%' }}>{eventDetails && eventDetails.proposedTransactions ? eventDetails.proposedTransactions.eRVRentIncrease : ""}</td>
                                    <td style={{ width: '17%' }}>{eventDetails && eventDetails.proposedTransactions ? eventDetails.proposedTransactions.eRVRentCAGR : ""}</td>
                                    <td style={{ width: '17%' }}>{eventDetails && eventDetails.proposedTransactions ? eventDetails.proposedTransactions.eRVRentBasis : ""}</td>
                                </tr>  
                                <tr>
                                    <td style={{ width: '15%' }} className={style.lightgray}>If on RPI Basis*</td>
                                    <td style={{ width: '17%' }}>{eventDetails && eventDetails.proposedTransactions ? eventDetails.proposedTransactions.ifOnRPIBasisAnnum : ""}</td>
                                    <td style={{ width: '17%' }}>{eventDetails && eventDetails.proposedTransactions ? eventDetails.proposedTransactions.ifOnRPIBasisRatePSF : ""}</td>
                                    <td style={{ width: '17%' }}>{eventDetails && eventDetails.proposedTransactions ? eventDetails.proposedTransactions.ifOnRPIBasisRentIncrease : ""}</td>
                                    <td style={{ width: '17%' }}>{eventDetails && eventDetails.proposedTransactions ? eventDetails.proposedTransactions.ifOnRPIBasisCAGR : ""}</td>
                                    <td style={{ width: '17%' }}>{eventDetails && eventDetails.proposedTransactions ? eventDetails.proposedTransactions.ifOnRPIBasisBasis : ""}</td>
                                </tr>  
                            </tbody>
                        </table>
                    </div>
                </AccordionItem>
                <AccordionItem defaultOpen={collapseExpand} id="accordion-item-4" title="Lease Details" className={`${style.accordstyle} ln-u-border ${!isFRFUpload ? style.disableevents : ""}`} >
                    <div className="ln-c-table-container">
                        <table className={style.table + ' ln-c-table'}>
                            <tbody className="ln-c-table__body">
                                <tr>
                                    <td className={style.lightgray} style={{ width: '25%' }}>Commencement Date :</td>
                                    <td style={{ width: '25%' }}>{eventDetails && eventDetails.leaseDetails ? eventDetails.leaseDetails.commencementDate : ""}</td>
                                    <td className={style.lightgray} style={{ width: '25%' }}>Lease Events :</td>
                                    <td style={{ width: '25%' }}>{eventDetails && eventDetails.leaseDetails ? eventDetails.leaseDetails.rentReviewDate : ""}</td>
                                </tr>
                                <tr>
                                    <td className={style.lightgray} style={{ width: '25%' }}>Expiry Date :</td>
                                    <td style={{ width: '25%' }}>{eventDetails && eventDetails.leaseDetails ? eventDetails.leaseDetails.expiryDate : ""}</td>
                                    <td className={style.lightgray} style={{ width: '25%' }}>Review Mechanism :</td>
                                    <td style={{ width: '25%' }}>{eventDetails && eventDetails.leaseDetails ? eventDetails.leaseDetails.reviewMechanism : ""}</td>
                                </tr>
                                <tr>
                                    <td className={style.lightgray} style={{ width: '25%' }}>Basis of Review :</td>
                                    <td style={{ width: '25%' }}>{eventDetails && eventDetails.leaseDetails ? eventDetails.leaseDetails.basisOfReview : ""}</td>
                                    <td className={style.lightgray} style={{ width: '25%' }}>Review Frequency :</td>
                                    <td style={{ width: '25%' }}>{eventDetails && eventDetails.leaseDetails ? eventDetails.leaseDetails.reviewFrequency : ""}</td>
                                </tr>
                                <tr>
                                    <td className={style.lightgray} style={{ width: '25%' }}>CAP :</td>
                                    <td style={{ width: '25%' }}>{eventDetails && eventDetails.leaseDetails ? eventDetails.leaseDetails.cap : ""}</td>
                                    <td className={style.lightgray} style={{ width: '25%' }}>Collar :</td>
                                    <td style={{ width: '25%' }}>{eventDetails && eventDetails.leaseDetails ? eventDetails.leaseDetails.collar : ""}</td>
                                </tr>
                                <tr>
                                    <td className={style.lightgray}>Valuation Assumption :</td>
                                    <td colSpan="3">{eventDetails && eventDetails.leaseDetails ? eventDetails.leaseDetails.valuationAssumption : ""}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </AccordionItem>
                <AccordionItem defaultOpen={collapseExpand} id="accordion-item-5" title="Key Comparables" className={`${style.accordstyle} ln-u-border ${!isFRFUpload ? style.disableevents : ""}`} >
                    <div className="ln-c-table-container">
                        <table className={style.table + ' ln-c-table'}>
                            <thead className="ln-c-table__header">
                                <tr className="ln-c-table__row ln-c-table__header-row">
                                    <th className="ln-c-table__header-cell" style={{ width: '12%'}}>Tenant</th>
                                    <th className="ln-c-table__header-cell" style={{ width: '12%' }}>Location</th>
                                    <th className="ln-c-table__header-cell" style={{ width: '12%' }}>Transaction</th>
                                    <th className="ln-c-table__header-cell" style={{ width: '12%' }}>Date</th>
                                    <th className="ln-c-table__header-cell" style={{ width: '10%' }}>Rate / ITZA PSF</th>
                                    <th className="ln-c-table__header-cell" style={{ width: '10%' }}>GIA / ITZA(SQ ft)</th>
                                    <th className="ln-c-table__header-cell" style={{ width: '10%' }}>Rent(£/P.A)</th>
                                    <th className="ln-c-table__header-cell" style={{ width: '22%' }}>Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    eventDetails && eventDetails.rentKeyComparables ? 
                                        eventDetails.rentKeyComparables.map(item => (
                                            item.tenant !== ""?
                                        <tr>
                                                <td>{item.tenant}</td>
                                                <td>{item.location}</td>
                                                <td>{item.trnsaction}</td>
                                                <td>{item.date}</td>
                                                <td>{item.rateITZApsf}</td>
                                                <td>{item.gia}</td>
                                                <td>{item.rent}</td>
                                                <td>{item.comments}</td>
                                        </tr>
                                       :""
                                        )) : ""}
                            </tbody>
                        </table>
                    </div>
                </AccordionItem>
                <AccordionItem defaultOpen={collapseExpand} id="accordion-item-6" title="Justification" className={`${style.accordstyle} ln-u-border ${!isFRFUpload ? style.disableevents : ""}`}>
                    <div>
                        <textarea readOnly rows="6" style={{ width: '100%', border: '1px solid #d8d8d8', color: '#000000', backgroundColor: '#FFFFFF' }}>{eventDetails && eventDetails.justification ? eventDetails.justification : ""}
                        </textarea>
                    </div>
                </AccordionItem>
                <AccordionItem defaultOpen={collapseExpand} id="accordion-item-7" title="Previous Summary Devaluation" className={`${style.accordstyle}  ln-u-border ${!isFRFUpload ? style.disableevents : ""}`} >
                    <div className="ln-c-table-container">
                        <table className={style.table + ' ln-c-table'}>
                            <thead className="ln-c-table__header">
                                <tr className="ln-c-table__row ln-c-table__header-row">
                                    <th className="ln-c-table__header-cell" style={{ width: '25%' }}>Component</th>
                                    <th className="ln-c-table__header-cell" style={{ width: '25%' }}>Area (SQ FT)</th>
                                    <th className="ln-c-table__header-cell" style={{ width: '25%' }}>Analysis (Rate PSF)</th>
                                    <th className="ln-c-table__header-cell" style={{ width: '25%' }}>Total Rent</th>
                                </tr>
                            </thead>
                            <tbody>
                                {eventDetails && eventDetails.previousSummaryDevaluation && eventDetails.previousSummaryDevaluation.summaryComponents ?
                                    eventDetails.previousSummaryDevaluation.summaryComponents.map(item => (
                                        <tr>
                                            <td>{item.component}</td>
                                            <td>{item.area}</td>
                                            <td>{item.analysis}</td>
                                            <td>{item.rent}</td>
                                        </tr>

                                    )):""
                                }

                                <tr>
                                    <td className={style.lightgray}>Total</td>
                                    <td>{eventDetails && eventDetails.previousSummaryDevaluation ? eventDetails.previousSummaryDevaluation.totalArea : ""}</td>
                                    <td>{eventDetails && eventDetails.previousSummaryDevaluation ? eventDetails.previousSummaryDevaluation.totalAnalysis : ""}</td>
                                    <td>{eventDetails && eventDetails.previousSummaryDevaluation ? eventDetails.previousSummaryDevaluation.totalRent : ""}</td>
                                </tr>
                                <tr>
                                    <td className={style.lightgray}>SAY</td>
                                    <td>{eventDetails && eventDetails.previousSummaryDevaluation ? eventDetails.previousSummaryDevaluation.sayArea : ""}</td>
                                    <td>{eventDetails && eventDetails.previousSummaryDevaluation ? eventDetails.previousSummaryDevaluation.sayAnalysis : ""}</td>
                                    <td>{eventDetails && eventDetails.previousSummaryDevaluation ? eventDetails.previousSummaryDevaluation.sayRent : ""}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </AccordionItem>
                <AccordionItem defaultOpen={collapseExpand} id="accordion-item-8" title="Proposed Summary Devaluation" className={`${style.accordstyle} ln-u-border ${!isFRFUpload ? style.disableevents : ""}`} >
                    <div className="ln-c-table-container">
                        <table className={style.table + ' ln-c-table'}>
                            <thead className="ln-c-table__header">
                                <tr className="ln-c-table__row ln-c-table__header-row">
                                    <th className="ln-c-table__header-cell" style={{ width: '25%' }}>Component</th>
                                    <th className="ln-c-table__header-cell" style={{ width: '25%' }}>Area (SQ FT)</th>
                                    <th className="ln-c-table__header-cell" style={{ width: '25%' }}>Analysis (Rate PSF)</th>
                                    <th className="ln-c-table__header-cell" style={{ width: '25%' }}>Total Rent</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                {eventDetails && eventDetails.proposedSummaryDevaluation && eventDetails.proposedSummaryDevaluation.summaryComponents ?
                                    eventDetails.proposedSummaryDevaluation.summaryComponents.map(item => (
                                            <tr>
                                                <td>{item.component}</td>
                                                <td>{item.area}</td>
                                                <td>{item.analysis}</td>
                                                <td>{item.rent}</td>
                                            </tr>

                                        )) : ""
                                }

                                <tr>
                                    <td className={style.lightgray}>Total</td>
                                    <td>{ eventDetails && eventDetails.proposedSummaryDevaluation? eventDetails.proposedSummaryDevaluation.totalArea :""}</td>
                                    <td>{eventDetails && eventDetails.proposedSummaryDevaluation ? eventDetails.proposedSummaryDevaluation.totalAnalysis : ""}</td>
                                    <td>{eventDetails && eventDetails.proposedSummaryDevaluation ? eventDetails.proposedSummaryDevaluation.totalRent : ""}</td>
                                </tr>
                                <tr>
                                    <td className={style.lightgray}>SAY</td>
                                    <td>{eventDetails && eventDetails.proposedSummaryDevaluation ? eventDetails.proposedSummaryDevaluation.sayArea : ""}</td>
                                    <td>{eventDetails && eventDetails.proposedSummaryDevaluation ? eventDetails.proposedSummaryDevaluation.sayAnalysis : ""}</td>
                                    <td>{eventDetails && eventDetails.proposedSummaryDevaluation ? eventDetails.proposedSummaryDevaluation.sayRent : ""}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </AccordionItem>
                <AccordionItem defaultOpen={collapseExpand} id="accordion-item-9" title="Approval" className={`${style.accordstyle} ln-u-border ${!isFRFUpload ? style.disableevents : ""}`} >
                    <div className="ln-c-table-container">
                        <table className={style.table + ' ln-c-table'}>
                            <tbody className="ln-c-table__body">
                                <tr style={{ lineHeight: '40px'}}>
                                    <td style={{width:'30%'}}>
                                        <div>Firm : <br /> {eventDetails && eventDetails.approvals ? eventDetails.approvals.firmSign : ""} </div>
                                    </td>
                                    <td style={{ width: '40%' }}>
                                        <div>Individual : <br /> {eventDetails && eventDetails.approvals ? eventDetails.approvals.individualSign  : ""} </div>
                                    </td>
                                    <td style={{ width: '30%' }}>
                                        <div> {eventDetails && eventDetails.approvals ? "Date:" +eventDetails.approvals.individualSignDate : ""} </div>
                                    </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '30%' }}>
                                           
                                        </td>
                                        <td style={{ width: '40%' }}>
                                        <div> <br /> {eventDetails && eventDetails.approvals ? eventDetails.approvals.individualSign2 : ""} </div>
                                        </td>
                                        <td style={{ width: '30%' }}>
                                        <div>{eventDetails && eventDetails.approvals ? "Date:" +eventDetails.approvals.individualSignDate2 : ""} </div>
                                        </td>
                                   
                                </tr>
                                <tr>
                                    <td>
                                        <div>SSL Estates Manager : </div>
                                    </td>
                                    <td>
                                        <div> {eventDetails && eventDetails.approvals ? eventDetails.approvals.sslEstateManager : ""}</div>
                                    </td>
                                    <td>
                                        <div>{eventDetails && eventDetails.approvals ? "Date:" + eventDetails.approvals.sslEstateManagerSignDate : ""} </div>
                                    </td>
                                </tr>
                                <tr style={{ lineHeight: '40px' }}>
                                    <td>
                                        <div>Head of Estate Management : </div>
                                    </td>
                                    <td>
                                        <div> {eventDetails && eventDetails.approvals && eventDetails.approvals.headEstateManager ? eventDetails.approvals.headEstateManager : ""} </div>
                                    </td>
                                    <td>
                                        <div> Date:{eventDetails && eventDetails.approvals && eventDetails.approvals.headEstateManagerSignDate ? eventDetails.approvals.headEstateManagerSignDate : ""} </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </AccordionItem>
                {(isFRFCreate && isFRFUpload) ?
                    <AccordionItem defaultOpen={collapseExpand} id="accordion-item-11" title="Notes" className={style.accordstyle + ' ln-u-border'} >
                        <div style={{ overflowY: 'auto' }}>
                            {newNotesAdded && noteCollection &&
                                (noteCollection).map(item => (
                                    <div style={{ marginBottom: '12px' }}>
                                        <div><b>  {item.addedBy} </b> at {moment(item.addedDate).format("DD-MM-YYYY HH:mm:ss")} </div>
                                        <div>{item.note}</div>
                                    </div>
                                ))
                            }
                            {newNotesAdded === false && eventDetails && eventDetails.notesData &&
                                (eventDetails.notesData).map(item => (
                                    <div style={{ marginBottom: '12px' }}>
                                        <div><b>  {item.addedBy} </b> at {moment(item.addedDate).format("DD-MM-YYYY HH:mm:ss")} </div>
                                        <div>{item.note}</div>
                                    </div>
                                ))
                            }


                        </div>
                        <div>
                            <textarea rows="6" value={addNote} style={{ width: '100%', border: '1px solid #adadad' }} onChange={(e) => { setaddNote(e.target.value); }} >
                            </textarea>
                            <OutlinedButton onClick={addEntryClick}>Add Note</OutlinedButton>
                        </div>
                    </AccordionItem>
                    : <></>
                }

                {(isFRFCreate && isFRFUpload) ?
                    <AccordionItem defaultOpen={collapseExpand} id="accordion-item-12" title="Attachments" className={style.accordstyle + ' ln-u-border'} >
                        {renderAttachments()}
                    </AccordionItem>
                    : <></>
                }

            </Accordion>

            {fileLoading ? 
                <ProgressIndicator />
                : <></>
            }

        </Page>
        )
};
