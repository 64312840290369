
export const links = [
    {
        id: 1,
        name: 'View All Properties',
        link: '/events/0/0/0',
        status: 0,
    },
    {
        id: 2,
        name: 'View All Time Completed',
        link: '/events/8/0/0',
        status: 8,
    },
    {
        id: 3,
        name: 'Import Budget Data',
        link: '/budget',
    },
];

