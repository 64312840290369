import React,{useState,useEffect} from 'react';
import ProgressIndicator from '../../components/ProgressIndicator'
import Page from '../../components/Page'
import { useMsal } from "@azure/msal-react";
import Fileupload from '../../components/Fileupload'
import { acquireToken } from "../../authConfig";
import CacheBuster from '../../CacheBuster';
import { TextInputField } from '@jsluna/form'


export default function TestBlob() {

    const { instance, accounts } = useMsal();
    const [selectedFile, setSelectedFile] = useState();
    const [progressbar, setProgressbar] = useState(false);
    const [blobCollection, setBlobCollection] = useState([]);

    //const changeHandler = (event) => {
    //    const file = event.target.files[0];
    //    if ((file.size / 1024 / 1024) < 25) {
    //        setSelectedFile(file);
    //    }
    //    else {
    //        event.target.value = "";
    //        alert("File size should be less than 25 MB");
    //    }
    //};

    //const downloadfile = (blobname) => {
    //    setProgressbar(true);
    //    acquireToken().then((response) => {
    //        fetch(window.location.origin + '/api/blob/DownloadBlob/' + blobname, {
    //            method: 'GET',
    //            headers: { Authorization: 'Bearer ' + response.accessToken },
    //        }).then(response => {
    //            if (response.ok) {
    //                response.blob().then(blob => {
    //                    let url = window.URL.createObjectURL(blob);
    //                    let a = document.createElement('a');
    //                    a.href = url;
    //                    a.download = blobname;
    //                    a.click();
    //                });
    //                setProgressbar(false);
    //            }
    //        }).catch((error) => {
    //            console.error('Error:', error);
    //            setProgressbar(false);
    //        });
    //    });        
    //};

    //const saveblob = () => {
    //    const formData = new FormData();
    //    if (selectedFile) {
    //        formData.append('formFile', selectedFile);
    //        formData.append('Id', 123);
    //        formData.append('addedBy', accounts[0].name);
    //        setProgressbar(true);
    //        acquireToken().then((response) => {
    //            fetch(window.location.origin + '/api/blob', {
    //                method: 'POST',
    //                headers: { Authorization: 'Bearer ' + response.accessToken },
    //                body: formData,
    //            }).then(response => response.json())
    //                .then(data => {
    //                    setProgressbar(false);
    //                    alert("File uploaded Successfully, Click below link to refresh.");
    //                })
    //                .catch((error) => {
    //                    console.error('Error:', error);
    //                    setProgressbar(false);
    //                });

    //        });
    //    }
    //};

    //const loadblob = () => {
    //    setProgressbar(true);
    //    acquireToken().then((response) => {
    //        fetch(window.location.origin + '/api/blob/GetBlobUploaded', {
    //            method: 'GET',
    //            headers: { Authorization: 'Bearer ' + response.accessToken },
    //        }).then(response => response.json())
    //            .then(data => {
    //                setBlobCollection(data);
    //                setProgressbar(false);
    //                if (data.length == 0) {
    //                    alert("No attachments present");
    //                }
    //            })
    //            .catch((error) => {
    //                console.error('Error:', error);
    //                setProgressbar(false);
    //            });

    //    });
    //};

    //useEffect(() => {
    //    loadblob();
    //}, []);

    return(
        <Page>
            <h3> Upload Attachments To Blob Storage...test </h3>
            <h4>Test 1234567812345678</h4>
            <div>
                <p> Bundle version - <code>v{global.appVersion}</code> </p>
             </div>
            {/*<Fileupload onClick={() => saveblob()} onChange={changeHandler} />*/}
            {/*<br /><br />*/}
            {/*<h3> <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => loadblob()}>Click here </span> to refresh/load uploaded attachments and click on filename to download </h3>*/}
            {/*<div>*/}
            {/*    <ol>*/}
            {/*        {*/}
            {/*            (blobCollection).map(item => (*/}
            {/*                <b><li style={{ cursor: 'pointer', textDecoration: 'underline', width: 'fit-content' }} onClick={() => downloadfile(item)}>{item}</li></b>*/}
            {/*            ))*/}
            {/*        }*/}
            {/*    </ol>*/}
            {/*</div>*/}

            {progressbar ?
                <ProgressIndicator />
                : <></>
            }
        </Page>
        
    )
}