import { useState, useEffect } from 'react';
import { acquireToken } from '../authConfig';
import { trackError } from '../appinsights/applogs';

export default function useFetch(url, ref) {
  const [data, setData] = useState(null);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    acquireToken().then((response) => {
      if (ref.current) {
        (async () => {
          try {
            const res = await fetch(url, {
              headers: {
                Authorization: 'Bearer ' + response.accessToken,
              },
            });
            //console.log("res: ", res);
            if (!res.ok) throw new Error(res.status);
            const resJson = await res.json();
            //console.log("Fetch Result: ", url, resJson)
            setData(resJson);
          } catch (err) {
            //console.log("Fetch Error: ", url, err)
            trackError(err);
            setError(err);
            setError(err);
          } finally {
            setLoading(false);
          }
        })();
      }
      return () => {
        ref.current = false;
      };
    });
  }, [url, ref]);
  return { data, loading, error };
}
