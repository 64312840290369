import React from 'react';

const PageSize = ({ recordsPerPage, setRecordsPerPage }) => {
  const options = [
    { label: '10', value: '10' },
    { label: '50', value: '50' },
    { label: '100', value: '100' },
  ];

  return (
    <div>
      <label htmlFor='recordsPerPage' className='ln-c-label ln-u-margin'>
        Per page
      </label>
      <select
        id='recordsPerPage'
        name='recordsPerPage'
        className='ln-c-select'
        style={{ width: 'auto' }}
        value={recordsPerPage}
        onChange={(e) => setRecordsPerPage(e.target.value)}
      >
        {options.map((o) => (
          <option key={o.value} value={o.value}>
            {o.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default PageSize;
