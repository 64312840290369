import React, { useState, useContext, useEffect, useRef } from 'react';
import '@jsluna/date-picker/react-dates-initialize';
import moment from 'moment';
import axios from 'axios';
import { GridWrapper, GridItem } from '@jsluna/grid';
import { DateRangePicker } from '@jsluna/date-picker';
import { FormGroup, TextInputField, SelectField } from '@jsluna/form';
import { MultiAutocompleteField } from '@jsluna/autocomplete';
import { FilledButton } from '@jsluna/button';
import { Card } from '@jsluna/card';
import { acquireToken } from '../../authConfig';
import { GlobalContext } from '../../context/GlobalState';

const FilterBar = ({ statusOptions }) => {
  moment.locale('en-GB');
  const { getEvents, filterParams, resetListingOptions } =
    useContext(GlobalContext);

  const {
    status,
    propertyType,
    propertySubType,
    eventType,
    sslManager,
    name,
    reviewDateStart,
    reviewDateEnd,
    leaseDescTenant,
    propertyEvent,
    dateIsBlank,
    agency,
    budgetStatus,
  } = filterParams;

  const [propertyNameFilter, setPropertyNameFilter] = useState(name);
  const [leaseDescTenantFilter, setLeaseDescTenantFilter] =
    useState(leaseDescTenant);
  const [propertyEventFilter, setPropertyEventFilter] = useState(propertyEvent);
  const [statusFilter, setStatusFilter] = useState(status);
  const [propertyTypeFilter, setPropertyTypeFilter] = useState(propertyType);
  const [propertySubTypeFilter, setpropertySubTypeFilter] =
    useState(propertySubType);
  const [eventTypeFilter, setEventTypeFilter] = useState(eventType);
  const [sslManagerFilter, setSslManagerFilter] = useState(
    sslManager === '' ? 'All' : sslManager
  );
  const [agencyFilter, setAgencyFilter] = useState(
        agency === '' ? 'All' : agency
    );
  const [budgetStatusFilter, setBudgetStatusFilter] = useState(budgetStatus === '' ? 'All' : budgetStatus);
  const [startDateFilter, setStartDate] = useState(reviewDateStart);
  const [endDateFilter, setEndDate] = useState(reviewDateEnd);
  const [dateFilterDisabled, setDateFilterDisabled] = useState(false);

  const [focusedInput, setFocusedInput] = useState(null);

  const [sslManagerOptions, setsslManagerOptions] = useState([]);
  const [agencyOptionss, setagencyOptionss] = useState([]);
  const [budgetStatusOptionss, setBudgetStatusOptionss] = useState([]);
  const [propertyTypeOptions, setPropertyTypeOptions] = useState([]);
  const [propertySubTypeOptions, setPropertySubTypeOptions] = useState([]);
  const [eventTypeOptions, seteventTypeOptions] = useState([]);
  const [dateIsBlankFilter, setDateIsBlankFilter] = useState(dateIsBlank);
   
    const propertySubTypeSelect = useRef(null);
    const propertyTypeSelect = useRef(null);
    const eventTypeSelect = useRef(null);

  const onApplyFilter = () => {
    resetListingOptions();
    getEvents(
      statusFilter,
      propertyTypeFilter,
      propertySubTypeFilter,
      eventTypeFilter,
      sslManagerFilter === 'All' ? '' : sslManagerFilter,
      propertyNameFilter,
      startDateFilter,
      endDateFilter,
      '',
      1,
      10,
      'Name',
      'ASC',
      '',
      '',
      'Yes',
      leaseDescTenantFilter,
      propertyEventFilter,
      dateIsBlankFilter,
      agencyFilter === 'All' ? '' : agencyFilter,
      budgetStatusFilter === 'All' ? '' : budgetStatusFilter
    );
  };

  const datesOutideRange = () => {
    return false;
  };

    const resetClick = () => {
    let pathsplit = window.location.pathname.split('/');
      setStatusFilter(parseInt(pathsplit[2], 10));

      if (parseInt(pathsplit[3], 10)) {
          const propType = [parseInt(pathsplit[3], 10)];
          setPropertyTypeFilter(propType);
          updateSelectedOptions(
              propType,
              propertyTypeOptions,
              propertyTypeSelect,
          );
      }
      else {
          setPropertyTypeFilter([]);
          updateSelectedOptions([], propertyTypeOptions, propertyTypeSelect);
      }

    if (parseInt(pathsplit[4], 10)) {
      const subType = [parseInt(pathsplit[4], 10)];
      setpropertySubTypeFilter(subType);
      updateSelectedOptions(
        subType,
        propertySubTypeOptions,
        propertySubTypeSelect
      );
    } else {
        setpropertySubTypeFilter([]);
        updateSelectedOptions([], propertySubTypeOptions, propertySubTypeSelect);
    }

    setPropertyNameFilter('');
    setEventTypeFilter([]);
    updateSelectedOptions([], eventTypeOptions, eventTypeSelect);

    setSslManagerFilter('All');
    setAgencyFilter('All');
    setBudgetStatusFilter('All');
    setStartDate(null);
    setEndDate(null);
    setLeaseDescTenantFilter('');
    setPropertyEventFilter('0');
    setDateIsBlankFilter(false);
    setDateFilterDisabled(false);
  };

  const updatePropertySubTypeFilter = (selectedItems) => {
    const values = selectedItems.map((i) => i.value);
    setpropertySubTypeFilter(values);
    };

    const updatePropertyTypeFilter = (selectedItems) => {
    const values = selectedItems.map((i) => i.value);
    setPropertyTypeFilter(values);
    };
  const updateEventTypeFilter = (selectedItems) => {
    const values = selectedItems.map((i) => i.value);
    setEventTypeFilter(values);
  };

  useEffect(() => {
    acquireToken().then((response) => {
      (async () => {
        const res = await axios.get(
          `${window.location.origin}/api/events/SSLManagers`,
          {
            headers: {
              Authorization: 'Bearer ' + response.accessToken,
            },
          }
        );

        const options = res.data.map((i) => {
          return { label: i.name, value: i.name };
        });

        setsslManagerOptions([{ label: 'All', value: 'All' }, ...options]);

        const selectListRes = await axios.get(
          `${window.location.origin}/api/selectlist/all`,
          {
            headers: {
              Authorization: 'Bearer ' + response.accessToken,
            },
          }
        );

        const eventTypes = selectListRes.data.eventTypes.map((et) => {
          return { label: et.description, value: et.value };
        });
          
        seteventTypeOptions(eventTypes);

        const propertySubTypes = selectListRes.data.propertySubTypes.map(
          (pst) => {
            return { label: pst.description, value: pst.value };
          }
        );

        setPropertySubTypeOptions(propertySubTypes);

        const propertyTypes = selectListRes.data.propertyTypes.map((pt) => {
          return { label: pt.description, value: pt.value };
        });

          setPropertyTypeOptions(propertyTypes);

          const agencyOptions = selectListRes.data.agencies.map((pt) => {
              return { label: pt.agencyName, value: pt.agencyName };
          });
          setagencyOptionss([{ label: 'All', value: 'All' }, { label: 'Not Assigned', value: '-1' }, ...agencyOptions]);

          const budgetOptions = selectListRes.data.budgetStatus.map((pt) => {
              return { label: pt.statusValue, value: pt.statusValue };
          });
          setBudgetStatusOptionss([{ label: 'All', value: 'All' }, { label: 'Not Required', value: '-1' }, ...budgetOptions]);
      
      })();
    });
  }, []);

    useEffect(() => {
       
    updateSelectedOptions(
      propertySubType,
      propertySubTypeOptions,
      propertySubTypeSelect
      );
  }, [propertySubTypeOptions]);

    useEffect(() => {
     
        updateSelectedOptions(
            propertyType,
            propertyTypeOptions,
            propertyTypeSelect
        );
    }, [propertyTypeOptions]);

  useEffect(() => {
    updateSelectedOptions(eventType, eventTypeOptions, eventTypeSelect);
  }, [eventTypeOptions]);

    const updateSelectedOptions = (selectedOptions, options, componentRef) => {
        
    var selected = options.filter((sto) =>
      selectedOptions.includes(Number(sto.value))
        );
        //alert(selected);
    componentRef.current.state.selectedOptions = selected;
  };

    const dateIsBlankChecked = (e) => {
      setDateIsBlankFilter(e.target.checked);
      setDateIsBlankFilter(e.target.checked);
  //  setDateFilterDisabled(e.target.checked);
  //  setStartDate(null);
  //  setEndDate(null);
  };

  return (
    <Card className='ln-u-border ln-u-margin-bottom'>
      <GridWrapper verticalAlign='middle'>
        <GridItem size={{ md: '1/4' }}>
          <SelectField
            name='status'
            label='Status'
            options={statusOptions}
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          />
              </GridItem>
              <GridItem size={{ md: '1/4' }}>
                  <MultiAutocompleteField
                      name='eventType'
                      label='Event Type'
                      options={eventTypeOptions}
                      ref={eventTypeSelect}
                      onSelect={(e) => updateEventTypeFilter(e)}
                  />
              </GridItem>
        <GridItem size={{ md: '1/4' }}>
          <SelectField
            name='sslManager'
            label='SSL Manager'
            options={sslManagerOptions}
            value={sslManagerFilter}
            onChange={(e) => setSslManagerFilter(e.target.value)}
          />
        </GridItem>
        <GridItem size={{ md: '1/4' }}>
          <TextInputField
            name='propertyName'
            label='Property Name'
            value={propertyNameFilter}
            onChange={(e) => setPropertyNameFilter(e.target.value)}
          />
        </GridItem>
     
            <GridItem size={{ md: '1/2' } }>
            <MultiAutocompleteField
            name='propertyType'
            label='Property Type'
            options={propertyTypeOptions}
            ref={propertyTypeSelect}
            onSelect={(e) => updatePropertyTypeFilter(e)}
          />
              </GridItem>
        <GridItem size={{ md: '1/2' }}>
          <MultiAutocompleteField
            name='propertySubType'
            label='Property Sub-Type'
            options={propertySubTypeOptions}
            ref={propertySubTypeSelect}
            onSelect={(e) => updatePropertySubTypeFilter(e)}
          />
        </GridItem>
        <GridItem size={{ md: '1/4' }}>
          <TextInputField
            name='leaseDescTenant'
            label='Lease Desc / Tenant'
            value={leaseDescTenantFilter}
            onChange={(e) => setLeaseDescTenantFilter(e.target.value)}
          />
        </GridItem>
        <GridItem size={{ md: '1/4' }}>
          <SelectField
            name='propertyEvent'
            label='Property Event'
            options={[
              { label: 'All', value: 0 },
              { label: 'Payable', value: 1 },
              { label: 'Receivable', value: 2 },
            ]}
            value={propertyEventFilter}
            onChange={(e) => setPropertyEventFilter(e.target.value)}
          />
        </GridItem>
        <GridItem size={{ md: '1/4' }}>
          <FormGroup name='reviewDate' label='Review Date'>
            <DateRangePicker
              startDate={startDateFilter}
              startDateId='drp-start-date'
              endDate={endDateFilter}
              endDateId='drp-end-date'
              onDatesChange={({ startDate, endDate }) => {
                setStartDate(startDate);
                setEndDate(endDate);
              }}
              focusedInput={focusedInput}
              onFocusChange={(input) => setFocusedInput(input)}
              numberOfMonths={2}
              startDatePlaceholderText='From Date'
              endDatePlaceholderText='To Date'
              displayFormat='DD/MM/YYYY'
              isOutsideRange={datesOutideRange}
              minimumNights={0}          
            />
          </FormGroup>
        </GridItem>
        <GridItem size={{ md: '1/4' }} style={{ paddingLeft: 0 }}>
          <div className='ln-u-display-flex ln-u-align-items-center'>
            <input
              type='checkbox'
              name='blank-date'
              style={{ height: '25px', width: '25px' }}
              checked={dateIsBlankFilter}
              onChange={(e) => dateIsBlankChecked(e)}
            />
            <label
              for='blank-date'
              className='ln-u-margin-left'
              style={{ marginBottom: 0 }}
            >
              Date Is Blank
            </label>
          </div>
        </GridItem>
              <GridItem size={{ md: '1/4' }}>
                  <SelectField
                      name='agency'
                      label='Agency'
                      options={agencyOptionss}
                      value={agencyFilter}
                      onChange={(e) => setAgencyFilter(e.target.value)}
                  />
              </GridItem>
              <GridItem size={{ md: '1/4' }}>
                  <SelectField
                      name='budgetStatus'
                      label='Budget Status'
                      options={budgetStatusOptionss}
                      value={budgetStatusFilter}
                      onChange={(e) => setBudgetStatusFilter(e.target.value)}
                  />
              </GridItem>
      </GridWrapper>
      <div className='ln-u-display-flex ln-u-justify-content-flex-end'>
        <FilledButton
          style={{ marginRight: '5px' }}
          fullWidth
          onClick={(e) => resetClick(e)}
          className='ln-u-1/10'
        >
          Reset
        </FilledButton>
        <FilledButton fullWidth onClick={onApplyFilter} className='ln-u-1/10'>
          Apply
        </FilledButton>
      </div>
    </Card>
  );
};

export default FilterBar;
