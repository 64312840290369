import React, { useEffect } from 'react';
import { Route } from 'react-router';
import { getAppInsights } from './appinsights/TelemetryService';
import TelemetryProvider from './appinsights/telemetry-provider';
import './custom.css';
import Header from './components/Header';
import Home from './pages/Home';
import Events from './pages/Events';
import Details from './pages/Details';
import Error from './pages/Error';
import TestBlob from './pages/Blob';
import Budget from './pages/Budget';
import ProgressIndicator from './components/ProgressIndicator';

import { GlobalProvider } from './context/GlobalState';

import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { msalConfig, loginRequest, appInsightKey } from './authConfig';
import CacheBuster from './CacheBuster';
import  './setupProxy';
import { Modal, ModalHeading } from '@jsluna/modal';
export default function App() {
    let appInsights = null;
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const [isOpen, setIsOpen] = React.useState(true);

    useEffect(() => {
        if (!isAuthenticated) {
            instance.loginRedirect(loginRequest).catch((error) => { });
        }
    }, []);

    const validateUserRole = () => {
        for (let account of accounts) {
            if (
                account.idTokenClaims &&
                account.idTokenClaims.aud === msalConfig.auth.clientId
            ) {
                if (
                    account.idTokenClaims.roles &&
                    account.idTokenClaims.roles.includes('EstateManager')
                )
                    return true;
            }
        }
        return false;
    };

    return (
        <TelemetryProvider
            instrumentationKey={appInsightKey}
            after={() => {
                appInsights = getAppInsights();
            }}
        >
            <CacheBuster>
                {({
                    loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        refreshCacheAndReload();
                    }
                    return (
                        <GlobalProvider>
                            {isAuthenticated && validateUserRole() ? (
                                <>
                                    <Header />
                                    <Route exact path='/' component={Home} />
                                    <Route
                                        exact
                                        path='/events/:status/:propertyType'
                                        component={Events}
                                    />
                                    <Route
                                        exact
                                        path='/events/:status/:propertyType/:squery'
                                        component={Events}
                                    />
                                    <Route exact path='/details/:id' component={Details} />
                                    <Route exact path='/testblob' component={TestBlob} />
                                    <Route exact path='/budget' component={Budget} />
                                </>

                            ) : !isAuthenticated ? (
                                <ProgressIndicator />
                            ) : !validateUserRole() ? (
                                <>
                                    <Header />
                                    <Error />
                                </>
                            ) : (
                                <></>
                            )}
                        </GlobalProvider>
                    );
                }}
            </CacheBuster>

        </TelemetryProvider>

    );
}
