import React, { useContext } from 'react';

import Page from '../../components/Page';
import EventListing from '../../components/EventListing';
import { trackError } from '../../appinsights/applogs';

import { GlobalContext } from '../../context/GlobalState';

const Events = () => {
    const { error } = useContext(GlobalContext);
    
  if (error) {
    trackError(error);
    return <div>Error</div>;
    }
 

  return (
    <Page>
          <h3 style={{ padding: '0px' }}>Lease Events</h3>
          <code style={{ color: '#F2F2F2' }}>{global.appVersion}</code>
      <EventListing />
    </Page>
  );
};

export default Events;
