import React from 'react';
import { Pagination } from '@jsluna/pagination';
import PageSize from '../../components/PageSize';

const Paging = ({
  itemCount,
  pagingData,
  setCurrentPage,
  setRecordsPerPage,
}) => {
  if (itemCount === 0) {
    return (
      <div className='ln-u-display-flex ln-u-justify-content-center ln-u-align-items-center ln-u-bg-color-white ln-u-padding ln-u-margin-top*2'>
        <span className='ln-u-display-1-fixed'>No Records Found</span>
      </div>
    );
  }

  const firstRecord =
    pagingData.currentPage * pagingData.pageSize - pagingData.pageSize + 1;

  const lastRecord = firstRecord + itemCount - 1;
  return (
    <div className='ln-u-display-flex ln-u-justify-content-space-between ln-u-align-items-center ln-u-bg-color-white ln-u-padding ln-u-margin-top ln-u-border'>
      <PageSize
        recordsPerPage={pagingData.pageSize}
        setRecordsPerPage={setRecordsPerPage}
      />
      <div>
        Showing <span className='ln-u-display-1-fixed'>{firstRecord}</span> to{' '}
        <span className='ln-u-display-1-fixed'>{lastRecord}</span> of{' '}
        <span className='ln-u-display-1-fixed'>{pagingData.totalCount}</span>
      </div>
      <Pagination
        showFirstAndLast
        showPages
        current={pagingData.currentPage}
        total={pagingData.totalPages}
        onChange={(page, e) => {
          e.preventDefault();
          setCurrentPage(page);
        }}
      />
    </div>
  );
};

export default Paging;
