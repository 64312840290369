import React, { useState, useContext, useEffect, useRef  } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import { IconButton, OutlinedButton } from '@jsluna/button';
import { Download, Edit, Plus, Cancel, List } from '@jsluna/icons';
import { Container } from '@jsluna/grid';
import { useMsal } from '@azure/msal-react';
import {
  TableContainer,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
} from '@jsluna/table';
import { useHistory } from 'react-router-dom';

import { ExportToCsv } from 'export-to-csv';
import style from './style.module.css';
import { trackError } from "../../appinsights/applogs";
import ProgressIndicator from '../../components/ProgressIndicator';
import Paging from '../../components/Paging';
import FilterBar from '../../components/FilterBar';
import NoteModal from '../../components/NoteModal';

import { GlobalContext } from '../../context/GlobalState';

import { currency } from '../../utils/format';
import { acquireToken } from '../../authConfig';
import { UpdatePropertyStatus, SetBudgetStatus, AssignAgencyToEvent } from '../../pages/Details/api';
import { SelectField } from '@jsluna/form';
import ReactExport from "react-export-excel";

const EventListing = () => {
  const { accounts } = useMsal();
  const ASCENDING = 'ascending';
  const DESCENDING = 'descending';

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const { propertyType } = useParams();
  const {
    loading,
    events,
    filterParams,
    listingOptions,
    updateListingOptions,
    getEvents,
    generateFRFZIP
  } = useContext(GlobalContext);

  const squery = filterParams.globalSearchQuery;
  const [query, setQuery] = useState('');

  const history = useHistory();
  const [selectedProperty, setSelectedProperty] = useState([]);
  const [checkAllFlag, setCheckAllFlag] = useState(false);
  const [statusValue, setStatusValue] = useState(0);
  const [isOpen, setIsOpen] = React.useState(false);
    const [fileLoading, setFileLoading] = useState(false);
  const [saveNoteSuccess, setSaveNoteSuccess] = useState(false);

  const [statusOptions, setStatusOptions] = useState([]);
    const [budgetStatusOptions, setBudgetOptions] = useState([]);
    const [agencyOptions, setAgencyOptions] = useState([]);
    const [statusribbon, setStatusribbon] = useState(false);

    const [excelBudgetdata, setExcelBudgetdata] = useState([]);
    const budgetExportRef = useRef(null);
    const reportExportRef = useRef(null);

  useEffect(() => {
    acquireToken().then((response) => {
      (async () => {
        const statusesRes = await axios.get(
          `${window.location.origin}/api/selectlist/Statuses`,
          {
            headers: {
              Authorization: 'Bearer ' + response.accessToken,
            },
          }
        );

        const statuses = statusesRes.data.map((s) => {
          return { label: s.description, value: s.value };
        });

        setStatusOptions([{ label: 'All', value: '0' }, ...statuses]);
      })();
    });
  }, []);
    useEffect(() => {
        acquireToken().then((response) => {
            (async () => {
                const selectListRes = await axios.get(
                    `${window.location.origin}/api/selectlist/all`,
                    {
                        headers: {
                            Authorization: 'Bearer ' + response.accessToken,
                        },
                    }
                );

                //budget status
                const budgetOptions = selectListRes.data.budgetStatus.map((pt) => {
                    return { label: pt.statusValue, value: pt.id };
                });
                setBudgetOptions(budgetOptions);
            })();
        });
    }, []);
    useEffect(() => {
        acquireToken().then((response) => {
            (async () => {
                const selectListRes = await axios.get(
                    `${window.location.origin}/api/selectlist/all`,
                    {
                        headers: {
                            Authorization: 'Bearer ' + response.accessToken,
                        },
                    }
                );

                //agency options
                const agencyOptions = selectListRes.data.agencies.map((pt) => {
                    return { label: pt.agencyName, value: pt.id };
                });

                setAgencyOptions(agencyOptions);

                
            })();
        });
    }, []);

  const csvoptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    title: 'Lease Events',
    useTextFile: false,
    useBom: false,
    useKeysAsHeaders: false,
    filename: `lease-events-${new Date().toLocaleString()}`,
    headers: [
      'Property Name',
      'Property Type',
      'Property Sub Type',
      'Lease Ref',
      'Lease Desc / Tenant',
      'Event Type',
      'Review Date',
      'Passing Rent',
      'Budget Rent',
      'SSL Manager',
      'Property Event Type',
      'Status',
    ],
    };
    const agencyChangeHandler = (e) => {
        let agencyval = e.target.value;

        if (!agencyval) {
            agencyval = 0;
        }
        if (selectedProperty.length > 0) {
            //setAgencyValue(agencyval);
            //setFileLoading(true);
            acquireToken().then((response) => {
                var res = AssignAgencyToEvent(selectedProperty, agencyval, response.accessToken);
                res.then((response) => {
                    if (response.status === 200) {
                       // setFileLoading(false);
                        localStorage.setItem("statusMsg", response.headers.get('message'));
                        setStatusribbon(true);
                        setTimeout(function () {
                            setStatusribbon(false);
                            localStorage.removeItem("statusMsg");
                        }, 2000);
                    }
                }).catch((error) => {
                   // setFileLoading(false);
                    console.error('Error:', error);
                    //trackError(error);
                });
            });
        }

    }
    const budgetStatusChangeHandler = (e) => {
        let budgetstateval = e.target.value;

        if (!budgetstateval) {
            budgetstateval = 0;
        }
        if (selectedProperty.length > 0) {
            //setBudgetStatusValue(budgetstateval);
            //setl(true);
            acquireToken().then((response) => {
                var res = SetBudgetStatus(selectedProperty, budgetstateval, response.accessToken);
                res.then((response) => {
                    if (response.status === 200) {
                        //setFileLoading(false);
                        localStorage.setItem("statusMsg", response.headers.get('message'));                        
                        console.log(response.headers.get('message'));
                        console.log(localStorage.getItem("statusMsg"));
                        setStatusribbon(true);
                        setTimeout(function () {
                            setStatusribbon(false);
                            localStorage.removeItem("statusMsg");
                        }, 2000);
                    }
                }).catch((error) => {
                    //setFileLoading(false)
                    console.error('Error:', error);
                    //trackError(error);
                });
            });
        }
    }
  const statusChangeHandler = (e) => {
    setStatusValue(e.target.value);
  };

  const renderUpdateState = () => {
    return (
      <div
        style={{
          display: 'inline-flex',
          float: 'right',
          marginBottom: '-25px',
        }}
      >
        <SelectField
          placeholder='Please Choose Status'
          name='status'
                options={
                    statusOptions.filter((so) => so.value !== '10' && so.value !== '11' && so.value !== '1' && so.value !== '0' )
                   
                }
          value={statusValue}
          onChange={statusChangeHandler}
        />

        <IconButton
          variant='outlined'
          label='Update Status'
          style={{ marginLeft: '5px', height: 'fit-content' }}
          onClick={updatePropertyStatus}
        >
          <Edit />
        </IconButton>
      </div>
    );
  };
  const selectAllHandler = (event) => {
    if (event.target.type === 'checkbox') {
      var selectedProp = selectedProperty;
      var currentPageItems = events.items.map((item) => item.id);
      if (event.target.checked) {
        selectedProp = selectedProp.filter(
          (item) => !currentPageItems.includes(item)
        );
        selectedProp = selectedProp.concat(events.items.map((item) => item.id));
        setCheckAllFlag(true);
      } else if (!event.target.checked) {
        selectedProp = selectedProp.filter(
          (item) => !currentPageItems.includes(item)
        );
        setCheckAllFlag(false);
      }

      setSelectedProperty(selectedProp);
    }
  };

  const selectHandler = (event) => {
    if (event.target.type === 'checkbox') {
      var value = parseInt(event.target.value, 10);
      var selectedPropVal = selectedProperty;
      if (event.target.checked && !selectedPropVal.includes(value)) {
        selectedPropVal = selectedPropVal.concat(value);
      } else if (!event.target.checked && selectedPropVal.includes(value)) {
        selectedPropVal = selectedPropVal.filter((val) => val !== value);
        setCheckAllFlag(false);
      }
      setSelectedProperty(selectedPropVal);
    }
  };

  const [sortedBy, setSortedBy] = useState({
    column: listingOptions.sortColumn,
    direction: listingOptions.sortAscending ? ASCENDING : DESCENDING,
  });

  useEffect(() => {
    setSortedBy({
      column: listingOptions.sortColumn,
      direction: listingOptions.sortAscending ? ASCENDING : DESCENDING,
    });
  }, [listingOptions]);

  const [perpageitem, setPerpageitem] = useState(listingOptions.pageSize);

  const checkbox_click = async (event) => {
    event.preventDefault();
    if (selectedProperty.length > 0) {
      //alert('Count : ' + selectedProperty.length + '\n' + selectedProperty);
        //debugger;
      generateFRFZIP(selectedProperty, accounts[0].name);
      setTimeout(function () {
        ongeneratefrfreload();
      }, 5000);
    } else {
      alert('Please check the properties to create FRF');
    }
  };

  const ongeneratefrfreload = () => {
    getEvents(
      filterParams.status,
      filterParams.propertyType,
      filterParams.propertySubType,
      filterParams.eventType,
      filterParams.sslManager,
      filterParams.name,
      filterParams.reviewDateStart,
      filterParams.reviewDateEnd,
      query,
      1,
      perpageitem ? perpageitem : 10,
      sortedBy.column,
      sortedBy.direction === ASCENDING ? 'ASC' : 'DESC',
      '',
      squery,
      filterParams.isApplyFilterCall,
      filterParams.leaseDescTenant,
      filterParams.propertyEvent,
      filterParams.dateIsBlank,
      filterParams.agency,
      filterParams.budgetStatus
    );

    setSelectedProperty([]);
    setCheckAllFlag(false);
  };

  const updatePropertyStatus = (e) => {
    if (selectedProperty.length > 0 && statusValue > 0) {
      acquireToken().then((response) => {
        var res = UpdatePropertyStatus(
          selectedProperty,
          statusValue,
          response.accessToken
        );
        res
          .then((response) => {
            if (response.ok) {
              console.log('update success');
              setSelectedProperty([]);
                setStatusValue(0);
                
              //alert('Update Successful');
            }
          })
          .catch((error) => {
            console.error('Error:', error);
             trackError(error);
          });
      });

        if (filterParams.status == 1) {
            var delayInMilliseconds = 2000; //4 second
           // if (selectedProperty.length != 10 || selectedProperty.length != 50 || selectedProperty.length != 100) {
                    setTimeout(function () {
                        
                    for (var i = 0; i < selectedProperty.length; i++) {
                        document.getElementById(selectedProperty[i]).style.visibility = "collapse";;
                        }
                    }, delayInMilliseconds);
            //    }
              

            //getEvents(
            //    filterParams.status,
            //    filterParams.propertyType,
            //    filterParams.propertySubType,
            //    filterParams.eventType,
            //    filterParams.sslManager,
            //    filterParams.name,
            //    filterParams.reviewDateStart,
            //    filterParams.reviewDateEnd,
            //    query,
            //    listingOptions.pageNumber,
            //    perpageitem, //10,
            //    sortedBy.column,
            //    sortedBy.direction === ASCENDING ? 'ASC' : 'DESC',
            //    '',
            //    squery,
            //    filterParams.isApplyFilterCall,
            //    filterParams.leaseDescTenant,
            //    filterParams.propertyEvent,
            //    filterParams.dateIsBlank
            //);
        }
    } else {
      alert(
        'Please check the properties to Update event status and Please choose the status'
      );
    }
  };
    
  useEffect(() => {
    if (filterParams.isApplyFilterCall) {
      setSelectedProperty([]);
      setCheckAllFlag(false);
    }
  }, [filterParams.isApplyFilterCall]);

  useEffect(() => {
    // if (squery) {
    //   setGlobalQuery(squery);
    // }
    getEvents(
      filterParams.status,
      filterParams.propertyType,
      filterParams.propertySubType,
      filterParams.eventType,
      filterParams.sslManager,
      filterParams.name,
      filterParams.reviewDateStart,
      filterParams.reviewDateEnd,
      query,
      listingOptions.pageNumber,
      perpageitem, //10,
      sortedBy.column,
      sortedBy.direction === ASCENDING ? 'ASC' : 'DESC',
      '',
      squery,
      filterParams.isApplyFilterCall,
      filterParams.leaseDescTenant,
      filterParams.propertyEvent,
      filterParams.dateIsBlank,
      filterParams.agency,
      filterParams.budgetStatus
    );
  }, [squery]);

  const setCurrentPage = (page) => {
    updateListingOptions({ pageNumber: page });
    getEvents(
      filterParams.status,
      filterParams.propertyType,
      filterParams.propertySubType,
      filterParams.eventType,
      filterParams.sslManager,
      filterParams.name,
      filterParams.reviewDateStart,
      filterParams.reviewDateEnd,
      query,
      page,
      events.paging.pageSize,
      sortedBy.column,
      sortedBy.direction === ASCENDING ? 'ASC' : 'DESC',
      'setCurrentPage',
      squery,
      filterParams.isApplyFilterCall,
      filterParams.leaseDescTenant,
      filterParams.propertyEvent,
      filterParams.dateIsBlank,
      filterParams.agency,
      filterParams.budgetStatus
    );

    setCheckAllFlag(false);
  };

  const setRecordsPerPage = (pageSize) => {
    setPerpageitem(pageSize);
    updateListingOptions({ pageSize: Number(pageSize), pageNumber: 1 });
    getEvents(
      filterParams.status,
      filterParams.propertyType,
      filterParams.propertySubType,
      filterParams.eventType,
      filterParams.sslManager,
      filterParams.name,
      filterParams.reviewDateStart,
      filterParams.reviewDateEnd,
      query,
      1,
      pageSize,
      sortedBy.column,
      sortedBy.direction === ASCENDING ? 'ASC' : 'DESC',
      'setRecordsPerPage',
      squery,
      filterParams.isApplyFilterCall,
      filterParams.leaseDescTenant,
      filterParams.propertyEvent,
      filterParams.dateIsBlank,
      filterParams.agency,
      filterParams.budgetStatus
    );

    var selectedProp = selectedProperty;
    var currentPageItems = events.items.map((item) => item.id);
    if (checkAllFlag) {
      selectedProp = selectedProp.filter(
        (item) => !currentPageItems.includes(item)
      );
      setCheckAllFlag(false);
    }
    setSelectedProperty(selectedProp);
  };

  const onRowClick = (event, row) => {
    if (event.target.type !== 'checkbox') {
      history.push(`/details/${row.id}`);
    }
  };

  const sort = (col, asc) => {
    setSortedBy({ column: col, direction: asc ? ASCENDING : DESCENDING });
    updateListingOptions({ sortColumn: col, sortAscending: asc });
    const order = asc ? 'ASC' : 'DESC';
    getEvents(
      filterParams.status,
      filterParams.propertyType,
      filterParams.propertySubType,
      filterParams.eventType,
      filterParams.sslManager,
      filterParams.name,
      filterParams.reviewDateStart,
      filterParams.reviewDateEnd,
      query,
      1,
      events.paging.pageSize,
      col,
      order,
      'sort',
      squery,
      filterParams.isApplyFilterCall,
      filterParams.leaseDescTenant,
      filterParams.propertyEvent,
      filterParams.dateIsBlank,
      filterParams.agency,
      filterParams.budgetStatus
    );
  };

    const getDataForCsv = (e) => {
    e.preventDefault();

    acquireToken().then((response) => {
        (async () => {
            setFileLoading(true);
        const res = await axios.get(
          `${window.location.origin}/api/events/all`,
          {
            headers: {
              Authorization: 'Bearer ' + response.accessToken,
            },
            params: {
              status: filterParams.status,
                propertyType: filterParams.propertyType.join(),
              propertySubType: filterParams.propertySubType.join(),
              eventType: filterParams.eventType.join(),
              sslManager: filterParams.sslManager,
              name: filterParams.name,
              reviewDateStart:
                filterParams.reviewDateStart?.format('YYYY-MM-DD'),
              reviewDateEnd: filterParams.reviewDateEnd?.format('YYYY-MM-DD'),
              searchQuery: query,
              pageNumber: 1,
              pageSize: -1,
              sortColumn: sortedBy.column,
              sortOrder: sortedBy.direction === ASCENDING ? 'ASC' : 'DESC',
              eventActionType: '',
              globalSearchQuery: squery,
              isApplyFilterCall: filterParams.isApplyFilterCall,
              leaseDescTenant: filterParams.leaseDescTenant,
              propertyEvent: filterParams.propertyEvent,
              dateIsBlank: filterParams.dateIsBlank,
              agency: filterParams.agency,
              budgetStatus: filterParams.budgetStatus,
            },
          }
        ).catch((error) => {
            setFileLoading(false);
            console.error('Error:', error);
            trackError(error);
        });

          setFileLoading(false);
        const csvExporter = new ExportToCsv(csvoptions);
        csvExporter.generateCsv(res.data);
      })();
    });
    };

    const budgetExport = (e) => {
        e.preventDefault();

        acquireToken().then((response) => {
            (async () => {
                setFileLoading(true);
                const res = await axios.get(
                    `${window.location.origin}/api/events/budgetdata`,
                    {
                        headers: {
                            Authorization: 'Bearer ' + response.accessToken,
                        },
                        params: {
                            status: filterParams.status,
                            propertyType: filterParams.propertyType.join(),
                            propertySubType: filterParams.propertySubType.join(),
                            eventType: filterParams.eventType.join(),
                            sslManager: filterParams.sslManager,
                            name: filterParams.name,
                            reviewDateStart:
                                filterParams.reviewDateStart?.format('YYYY-MM-DD'),
                            reviewDateEnd: filterParams.reviewDateEnd?.format('YYYY-MM-DD'),
                            searchQuery: query,
                            pageNumber: 1,
                            pageSize: -1,
                            sortColumn: sortedBy.column,
                            sortOrder: sortedBy.direction === ASCENDING ? 'ASC' : 'DESC',
                            eventActionType: '',
                            globalSearchQuery: squery,
                            isApplyFilterCall: filterParams.isApplyFilterCall,
                            leaseDescTenant: filterParams.leaseDescTenant,
                            propertyEvent: filterParams.propertyEvent,
                            dateIsBlank: filterParams.dateIsBlank,
                            agency: filterParams.agency,
                            budgetStatus: filterParams.budgetStatus,
                        },
                    }
                ).catch((error) => {
                    setFileLoading(false);
                    console.error('Error:', error);
                    trackError(error);
                });

                setFileLoading(false);
                setExcelBudgetdata(res.data);
                budgetExportRef.current.click();

            })();
        });
    };

    const reportExport = (e) => {
        e.preventDefault();

        acquireToken().then((response) => {
            (async () => {
                setFileLoading(true);
                const res = await axios.get(
                    `${window.location.origin}/api/events/budgetdata`,
                    {
                        headers: {
                            Authorization: 'Bearer ' + response.accessToken,
                        },
                        params: {
                            status: filterParams.status,
                            propertyType: filterParams.propertyType.join(),
                            propertySubType: filterParams.propertySubType.join(),
                            eventType: filterParams.eventType.join(),
                            sslManager: filterParams.sslManager,
                            name: filterParams.name,
                            reviewDateStart:
                                filterParams.reviewDateStart?.format('YYYY-MM-DD'),
                            reviewDateEnd: filterParams.reviewDateEnd?.format('YYYY-MM-DD'),
                            searchQuery: query,
                            pageNumber: 1,
                            pageSize: -1,
                            sortColumn: sortedBy.column,
                            sortOrder: sortedBy.direction === ASCENDING ? 'ASC' : 'DESC',
                            eventActionType: '',
                            globalSearchQuery: squery,
                            isApplyFilterCall: filterParams.isApplyFilterCall,
                            leaseDescTenant: filterParams.leaseDescTenant,
                            propertyEvent: filterParams.propertyEvent,
                            dateIsBlank: filterParams.dateIsBlank,
                            agency: filterParams.agency,
                            budgetStatus: filterParams.budgetStatus,
                        },
                    }
                ).catch((error) => {
                    setFileLoading(false);
                    console.error('Error:', error);
                    trackError(error);
                });

                setFileLoading(false);
                setExcelBudgetdata(res.data);
                reportExportRef.current.click();

            })();
        });
    };

  let displayColumns = [
    {
      name: 'Property Name',
      dbColumn: 'Name',
      accessor: (rowData) => ({ value: rowData.name }),
      hideLabel: true,
      className: 'customCol',
    },
    {
      name: 'Property Type',
      dbColumn: 'PropertyType',
      accessor: (rowData) => ({ value: rowData.propertyType }),
      hideLabel: true,
      className: 'customCol',
    },
    {
      name: 'Prop Sub Type',
      dbColumn: 'PropertySubType',
      accessor: (rowData) => ({ value: rowData.propertySubType }),
      hideLabel: true,
      className: 'customCol',
    },
    {
      name: 'Lease Ref',
      dbColumn: 'LeaseRef',
      accessor: (rowData) => ({ value: rowData.leaseRef }),
      hideLabel: true,
      className: 'customCol',
    },
    {
      name: 'Lease Desc / Tenant',
      dbColumn: 'LeasePayableDescTenant',
      accessor: (rowData) => ({ value: rowData.leasePayableDescTenant }),
      hideLabel: true,
      className: 'customCol',
    },
    {
      name: 'Event Type',
      dbColumn: 'EventType',
      accessor: (rowData) => ({ value: rowData.eventType }),
      hideLabel: true,
      className: 'customCol',
    },
    {
      name: 'Review Date',
      dbColumn: 'ReviewDate',
      accessor: (rowData) => ({
        value: new Date(rowData.reviewDate).toLocaleDateString(),
      }),
      hideLabel: true,
      className: 'customCol',
    },
    {
      name: 'Passing Rent',
      dbColumn: 'CurrentBudget',
      accessor: (rowData) => ({ value: rowData.currentRent }),
      hideLabel: true,
      className: 'customCol',
      sort: (accessor, ascending) => (a, b) => {
        const valueA = parseInt(accessor(a).value, 10);
        const valueB = parseInt(accessor(b).value, 10);

        if (valueA < valueB) {
          return ascending ? -1 : 1;
        }
        if (valueA > valueB) {
          return ascending ? 1 : -1;
        }
        return 0;
      },
      render: ({ value }) => (
        <span>
          {parseInt(value).toLocaleString('en-GB', {
            style: 'currency',
            currency: 'GBP',
          })}
        </span>
      ),
    },
    {
      name: 'Budget Rent',
      dbColumn: 'BudgetRent',
      accessor: (rowData) => ({ value: rowData.budgetRent }),
      hideLabel: true,
      className: 'customCol',
      sort: (accessor, ascending) => (a, b) => {
        const valueA = parseInt(accessor(a).value, 10);
        const valueB = parseInt(accessor(b).value, 10);

        if (valueA < valueB) {
          return ascending ? -1 : 1;
        }
        if (valueA > valueB) {
          return ascending ? 1 : -1;
        }
        return 0;
      },
      render: ({ value }) => (
        <span>
          {parseInt(value).toLocaleString('en-GB', {
            style: 'currency',
            currency: 'GBP',
          })}
        </span>
      ),
    },
    {
      name: 'SSL Manager',
      dbColumn: 'SSLManager',
      accessor: (rowData) => ({ value: rowData.sslManager }),
      hideLabel: true,
      className: 'customCol',
    },
    {
      name: 'Status',
      dbColumn: 'Status',
      accessor: (rowData) => ({ value: rowData.status }),
      hideLabel: true,
      className: 'customCol',
      },
      {
          name: 'Agency',
          dbColumn: 'Agency',
          accessor: (rowData) => ({ value: rowData.agency }),
          hideLabel: true,
          className: 'customCol',
      },
      {
          name: 'Budget Status',
          dbColumn: 'BudgetStatus',
          accessor: (rowData) => ({ value: rowData.budgetStatus }),
          hideLabel: true,
          className: 'customCol',
      },
  ];

  const saveComplete = () => {
    setSaveNoteSuccess(true);
    setSelectedProperty([]);
    setIsOpen(false);

    setTimeout(() => {
      setSaveNoteSuccess(false);
    }, 5000);
  };

  const closeSuccess = (e) => {
    e.preventDefault();
    setSaveNoteSuccess(false);
  };

  if (propertyType !== '0') {
    displayColumns = displayColumns.filter((c) => c.name !== 'Property Type');
  }

  //if (status !== '0') {
  //  displayColumns = displayColumns.filter((c) => c.name !== 'Status');
  //}

  if (loading) {
    return <ProgressIndicator />;
  }
    const message = localStorage.getItem("name") === 'undefined' ? "" : localStorage.getItem("name");
    
  return (
    <>
      <NoteModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        selectedProperties={selectedProperty}
        saveComplete={saveComplete}
          />
          <ExcelFile filename={`lease-events-budget-${new Date().toLocaleString()}`} element={<div ref={budgetExportRef}></div>}>
              <ExcelSheet data={excelBudgetdata.events} name="Lease Event Budget">
                  <ExcelColumn label="LeaseEvents_ID" value="id" />
                  <ExcelColumn label="Store Number" value="storeNumber" />
                  <ExcelColumn label="Property Name" value="name" />
                  <ExcelColumn label="Property Type" value="propertyType" />
                  <ExcelColumn label="Property Sub Type" value="propertySubType" />
                  <ExcelColumn label="Lease Ref" value="leaseRef" />
                  <ExcelColumn label="Lease Desc / Tenant" value="leasePayableDescTenant" />
                  <ExcelColumn label="Event Type" value="eventType" />
                  <ExcelColumn label="Review Date" value={(values) => {
                      var rdate = new Date(values.reviewDate);
                      var offset = Math.abs(rdate.getTimezoneOffset());
                      return values.reviewDate ? new Date(rdate.getFullYear(), rdate.getMonth(), rdate.getDate(), Math.floor(offset / 60), (offset % 60), 0) : null;
                  }} />
                  <ExcelColumn label="Passing Rent" value="currentBudget" />
                  <ExcelColumn label="SSL Manager" value="sslManager" />
                  <ExcelColumn label="Property Event Type" value="propertyEventType" />
                  <ExcelColumn label="Event Status" value="statusDisplay" />
                  <ExcelColumn label="Budget Status" value="budgetStatus" />
                  <ExcelColumn label="Landlord Name" value="landlordName" />
                  <ExcelColumn label="Lease Start Date" value={(values) => {
                    //  values.startDate ? new Date(values.startDate) : null
                      var sdate = new Date(values.startDate);
                      var offset = Math.abs(sdate.getTimezoneOffset());
                      return values.startDate ? new Date(sdate.getFullYear(), sdate.getMonth(), sdate.getDate(), Math.floor(offset / 60), (offset % 60), 0) : null;
                  }} />
                  <ExcelColumn label="Lease End Date" value={(values) => {
                    //  values.endDate ? new Date(values.endDate) : null
                      var edate = new Date(values.endDate);
                      var offset = Math.abs(edate.getTimezoneOffset());
                      return values.endDate ? new Date(edate.getFullYear(), edate.getMonth(), edate.getDate(), Math.floor(offset / 60), (offset % 60), 0) : null;
                  }} />
                  <ExcelColumn label="Agency" value="agency" />
                  <ExcelColumn label="Budget Rent" value={(values) => values.budgetRent ? values.budgetRent : null} />
                  <ExcelColumn label="Comment" value="" />
              </ExcelSheet>
              <ExcelSheet data={excelBudgetdata.agency} name="List of Agency">
                  <ExcelColumn label="Agency Id" value="id" />
                  <ExcelColumn label="AgencyName" value="name" />
              </ExcelSheet>
          </ExcelFile>
          <ExcelFile filename={`lease-events-${new Date().toLocaleString()}`} element={<div ref={reportExportRef}></div>}>
              <ExcelSheet data={excelBudgetdata.events} name="Lease Event">
                  <ExcelColumn label="Store Number" value="storeNumber" />
                  <ExcelColumn label="Property Name" value="name" />
                  <ExcelColumn label="Property Type" value="propertyType" />
                  <ExcelColumn label="Property Sub Type" value="propertySubType" />
                  <ExcelColumn label="Lease Ref" value="leaseRef" />
                  <ExcelColumn label="Lease Desc / Tenant" value="leasePayableDescTenant" />
                  <ExcelColumn label="Event Type" value="eventType" />
                  <ExcelColumn label="Review Date" value={(values) => {
                    //  values.reviewDate ? new Date(values.reviewDate) : null
                      var rdate = new Date(values.reviewDate);
                      var offset = Math.abs(rdate.getTimezoneOffset());
                      return values.reviewDate ? new Date(rdate.getFullYear(), rdate.getMonth(), rdate.getDate(), Math.floor(offset / 60), (offset % 60), 0) : null;
                  }} />
                  <ExcelColumn label="Passing Rent" value="currentBudget" />
                  <ExcelColumn label="Budget Rent" value="budgetRent" />
                  <ExcelColumn label="SSL Manager" value="sslManager" />
                  <ExcelColumn label="Property Event Type" value="propertyEventType" />
                  <ExcelColumn label="Status" value="statusDisplay" />
                  <ExcelColumn label="Agency" value="agency" />
                  <ExcelColumn label="Budget Status" value="budgetStatus" />
                  <ExcelColumn label="Notes" value="notes" />
              </ExcelSheet>
          </ExcelFile>
          <FilterBar statusOptions={statusOptions} />
          <div className='ln-u-bg-color-info ln-u-color-white ln-u-margin-bottom ln-u-padding-sides*2 ln-u-display-flex ln-u-justify-content-space-between ln-u-align-items-center'>
              <span> {message}</span></div>
     
      <div className='ln-u-bg-color-white ln-u-border ln-u-display-flex ln-u-justify-content-space-between ln-u-margin-bottom ln-u-padding'>
        <OutlinedButton
          onClick={() => {
            history.push('/');
          }}
        >
          Back
        </OutlinedButton>
        <div className='ln-u-display-flex'>
                  <IconButton
                      name='budgetExportBtn'
                      variant='outlined'
                      label='Budget Export'
                      onClick={(e) => budgetExport(e)}
                      className='ln-u-margin-right*1/2'
                  >
                      <Download />
                  </IconButton>
                  <IconButton
                      name='csvExportBtn'
            variant='outlined'
            label='Export'
                      onClick={(e) => reportExport(e)}
          >
            <Download />
          </IconButton>
        </div>
          </div>
          {statusribbon ? <div className={style.rrsuccessalert} style={{ padding: '20px', backgroundColor: '#5bc67a8f', color: '#000000', marginBottom: '15px' }}>{localStorage.getItem("statusMsg") === 'undefined' ? "" : localStorage.getItem("statusMsg")}</div> : ""}
                <div
              className={
                  selectedProperty.length > 0
                      ? 'ln-u-display-flex ln-u-margin-right*1/2'
                      : 'ln-u-display-none'
              }
          >
              <div style={{ paddingRight: '5px' }}>
              <SelectField
                 
                  style={{ height: 'fit-content', display: 'inline-flex'  }}
                  placeholder="Agency Not Assigned"
                  name='agency'
                  options={agencyOptions}
                   onChange={agencyChangeHandler}

              />
              </div>
              <div style={{ paddingRight: '5px' }}>
              <SelectField

                  style={{ height: 'fit-content', display: 'inline-flex' }}
                  placeholder="Not Required"
                  name='budgetstatus'
                  options={budgetStatusOptions}
                  onChange={budgetStatusChangeHandler}
              /></div>
              <IconButton
                  variant='outlined'
                  label='Add Note'
                  onClick={() => setIsOpen(true)}
                  className='ln-u-margin-right*1/2'
                  style={{ height: 'fit-content', display: 'inline-flex' }}
              >
                  <Plus />
              </IconButton>

              <IconButton
                  variant='outlined'
                  label='Create FRF'
                  onClick={checkbox_click}
                  className='ln-u-margin-right*1/2'
                  style={{ height: 'fit-content', display: 'inline-flex' }}
              >
                  <List />
              </IconButton>
           
            {renderUpdateState()}
                  </div>
                 
       
                
      {saveNoteSuccess ? (
        <div className='ln-u-bg-color-success ln-u-color-white ln-u-margin-bottom ln-u-padding-sides*2 ln-u-display-flex ln-u-justify-content-space-between ln-u-align-items-center'>
          <span>Note added successfully.</span>
          <IconButton
            variant='text'
            label='Close'
            hideLabel
            onClick={closeSuccess}
            className='ln-u-color-white'
          >
            <Cancel />
          </IconButton>
        </div>
      ) : (
        ''
      )}
      <Container className='ln-u-border'>
        <TableContainer sortable responsive>
          <TableHeader sortLabel>
            <TableHeaderRow>
              <TableHeaderCell>
                <input
                  type='checkbox'
                  onChange={(e) => selectAllHandler(e)}
                  checked={checkAllFlag}
                />
              </TableHeaderCell>
              {displayColumns.map((column) => (
                <TableHeaderCell
                  className={style.cellpadding}
                  key={column.name}
                  sortDirection={
                    sortedBy.column === column.dbColumn
                      ? sortedBy.direction
                      : undefined
                  }
                  onSort={(ascending) => sort(column.dbColumn, !ascending)}
                >
                  {column.name}
                </TableHeaderCell>
              ))}
            </TableHeaderRow>
          </TableHeader>
          <TableBody>
            {events.items.map((row) => (
              <TableRow
                id={row.id}
                key={row.id}
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  onRowClick(e, row);
                }}
              >
                <TableCell>
                  <input
                    key={row.id}
                    type='checkbox'
                    value={row.id}
                    onChange={(e) => selectHandler(e)}
                    checked={
                      selectedProperty.includes(parseInt(row.id, 10))
                        ? true
                        : false
                    }
                  />
                </TableCell>
                <TableCell className={style.cellpadding}>{row.name}</TableCell>
                {propertyType === '0' && (
                  <TableCell className={style.cellpadding}>
                    {row.propertyType}
                  </TableCell>
                )}
                <TableCell className={style.cellpadding}>
                  {row.propertySubType}
                </TableCell>
                <TableCell className={style.cellpadding}>
                  {row.leaseRef}
                </TableCell>
                <TableCell className={style.cellpadding}>
                  {row.leasePayableDescTenant}
                </TableCell>
                <TableCell className={style.cellpadding}>
                  {row.eventType}
                </TableCell>
                <TableCell className={style.cellpadding}>
                  {row.reviewDate !== null
                    ? new Date(row.reviewDate).toLocaleDateString()
                    : ''}
                </TableCell>
                <TableCell className={style.cellpadding}>
                  {currency.format(row.currentBudget)}
                </TableCell>
                <TableCell className={style.cellpadding}>
                  {currency.format(row.budgetRent)}
                </TableCell>
                <TableCell className={style.cellpadding}>
                  {row.sslManager}
                </TableCell>
                <TableCell
                  className={style.cellpadding}
                  onClick={(e) => {
                    history.push(`/details/${row.id}`);
                  }}
                >
                  {row.statusDisplay}
                    </TableCell>
                    <TableCell className={style.cellpadding}>
                        {row.agency}
                    </TableCell>
                    <TableCell className={style.cellpadding}>
                        {row.budgetStatus}
                    </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableContainer>
      </Container>
      <Paging
        itemCount={events.items.length}
        pagingData={events.paging}
        setCurrentPage={setCurrentPage}
        setRecordsPerPage={setRecordsPerPage}
          />
          {fileLoading ?
              <ProgressIndicator />
              : <></>
          }
    </>
  );
};

export default EventListing;
