import React from 'react';

import { Input } from '@jsluna/form';
import { FilledButton } from '@jsluna/button';

export default function FileUpload({ onChange, onClick, acceptExtension, name }) {
    
    return (
        <>
            <Input type="file" accept={acceptExtension} onChange={onChange} />
            <FilledButton style={{ height: 'fit-content', marginRight: '5px' }} onClick={onClick}>{name}</FilledButton>
        </>
    )
}